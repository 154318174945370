import React, { useEffect, useState } from 'react';
import TopToolbar from '../TopToolbar/TopToolbar';
import styles from './index.module.css';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import { FileDownload } from '../../Types/FileDownLoad';
import NewTextingPopup from '../Texting/NewTextingPopup';
import { fetchUserTextConversations } from '../../Actions';

export default function AppLayout({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const dispatch = useAppDispatch();
  const { show, fileDownload } = useAppSelector((state) => state.docView);
  const { conversationsList } = useAppSelector((state) => state.conversations);
  const { userModel } = useAppSelector((state) => state.user);

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const getReferences = async () => {
    if (userModel && userModel.user) {
      await Promise.all([
        dispatch(fetchUserTextConversations(userModel.user.userId)),
      ]);
    }
  };
  useEffect(() => {
    getReferences();
  }, []);
  return (
    <div className={styles.mainScreen}>
      <TopToolbar />
      {children}
      <div className='d-flex position-fixed bottom-0' style={{ right: 0 }}>
        {conversationsList.map((convo) => (
          <NewTextingPopup key={convo.id} convo={convo} />
        ))}
      </div>
      <DocViewModal
        show={show}
        setShow={setShow}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </div>
  );
}
