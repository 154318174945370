import React, { useState, useEffect } from 'react';
import { Spinner, Form, Button } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { ExcessDetailObj } from '../../ApiTypes/ExcessDetailObj';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppSelector } from '../../Reducers/Store';
import { downloadExcel } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import PageScaffold from '../PageScaffold/PageScaffold';
import ExcessDetailTable from './ExcessDetailTable';
import styles from './index.module.css';

export default function ExcessDetail() {
  const [years, setYears] = useState<number[]>([]);
  const [details, setDetails] = useState<ExcessDetailObj[]>([]);

  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  let formValues: { insurCoId: number; treatyYear: number };

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var lowYear = new Date().getFullYear() - 1970;
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= lowYear; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSubmit = (values: { insurCoId: number; treatyYear: number }) => {
    return getDetails(values.insurCoId, values.treatyYear);
  };

  const getDetails = (insurCoId: number, treatyYear: number) => {
    return ExcessCarrierApi.getExcessReport(insurCoId, treatyYear)
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get details');
      });
  };

  const getExport = () => {
    return ExcessCarrierApi.getExport(
      formValues.insurCoId,
      formValues.treatyYear
    )
      .then((res) => {
        downloadExcel(res.data.fileName, res.data.file);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to export');
      });
  };
  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaFileExcel className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Excess Detail Report</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <RFForm
          onSubmit={onSubmit}
          initialValues={{
            treatyYear: new Date().getFullYear(),
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formValues = values;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex  align-items-center gap1Rem'>
                  <Field
                    name='insurCoId'
                    label='Insurance Company'
                    options={[
                      ...insuranceCompaniesForUser
                        .map((x) => x)
                        .sort((a, b) => +a.accountnumber! - +b.accountnumber!),
                    ]}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid!} value={o.insurcoid!}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='treatyYear'
                    label='Treaty Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />

                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <Button
          type='button'
          variant='outline-primary'
          size='sm'
          onClick={getExport}
        >
          Export
        </Button>
      </div>
      <ExcessDetailTable data={details} />
    </PageScaffold>
  );
}
