import { addDays, format } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaHistory } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { EDIStatus } from '../../ApiTypes/EdiStatus';
import { EdiTransaction } from '../../ApiTypes/EdiTransaction';
import { useAppSelector } from '../../Reducers/Store';
import { parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import EditEdiModal from '../EdiForms/EditEdiModal';
import PageScaffold from '../PageScaffold/PageScaffold';
import EdiHistoryTable from './EdiHistoryTable';

import styles from './index.module.css';
import { useParams } from 'react-router-dom';

export default function EdiHistory() {
  const { statusId } = useParams();
  const { userModel } = useAppSelector((state) => state.user);
  const [transactions, setTransactions] = useState<EdiTransaction[]>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<EdiTransaction | null>(null);
  let formApi: FormApi<
    { status: string; fromDate: string; toDate: string },
    Partial<{ status: string; fromDate: string; toDate: string }>
  >;

  const ediStatusOptions = [
    {
      label: 'All Received',
      value: 1,
    },
    {
      label: 'New (Not Sent Yet)',
      value: 2,
    },
    {
      label: 'FROI Received and Need SROI',
      value: 3,
    },
    {
      label: 'FROI with No SROI MO and Closed',
      value: 7,
    },
    {
      label: 'Pending (Structurally Invalid)',
      value: 4,
    },
    {
      label: 'Received with Errors',
      value: 5,
    },
    {
      label: 'Sent and Waiting Response',
      value: 6,
    },
  ];

  const onSubmit = (values: {
    status: string;
    fromDate: string;
    toDate: string;
  }) => {
    console.log(values);
    if (!userModel?.adjuster) {
      toast.error('Are you an adjuster?');
    }
    switch (`${values.status}`) {
      case '1':
        return getByStatus(
          EDIStatus.AcknowledgementReceivedSuccess,
          values.fromDate,
          values.toDate
        );
      case '2':
        return getByStatus(EDIStatus.New, values.fromDate, values.toDate);
      case '3':
        return getFROIsNeedingSROIs(values.fromDate, values.toDate);
      case '4':
        return getByStatus(
          EDIStatus.RequiredFilesMissing,
          values.fromDate,
          values.toDate
        );
      case '5':
        return getByStatus(EDIStatus.Errors, values.fromDate, values.toDate);
      case '6':
        return getPending(values.fromDate, values.toDate);
      case '7':
        return getFROInoSROIMOClosed(values.fromDate, values.toDate);
      default:
        break;
    }
  };

  const getPending = (start: string, end: string) => {
    return EdiTransactionApi.getPending(start, end)
      .then((res) => {
        res.data.sort((a, b) => {
          const aName = new Date(a.eventDate);
          const bName = new Date(b.eventDate);
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
        setTransactions(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get transactions');
      });
  };

  const getByStatus = (status: number, start: string, end: string) => {
    return EdiTransactionApi.getByStatus(status, start, end)
      .then((res) => {
        res.data.sort((a, b) => {
          const aName = new Date(a.eventDate);
          const bName = new Date(b.eventDate);
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
        setTransactions(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get transactions');
      });
  };

  const getFROIsNeedingSROIs = (start: string, end: string) => {
    return EdiTransactionApi.getFROIsNeedingSROIs(start, end)
      .then((res) => {
        res.data.sort((a, b) => {
          const aName = new Date(a.eventDate);
          const bName = new Date(b.eventDate);
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
        setTransactions(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get transactions');
      });
  };

  const getFROInoSROIMOClosed = (start: string, end: string) => {
    return EdiTransactionApi.getFROInoSROIMOClosed(start, end)
      .then((res) => {
        res.data.sort((a, b) => {
          const aName = new Date(a.eventDate);
          const bName = new Date(b.eventDate);
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
        setTransactions(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get transactions');
      });
  };

  const closeEditModal = () => {
    setSelectedTransaction(null);
    setShowEdit(false);
  };

  const openEditModal = (t: EdiTransaction) => {
    setSelectedTransaction(t);
    setShowEdit(true);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaHistory className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>EDI Dashboard</h1>
            <p>Use this screen to search and filter EDI Transactions</p>
          </div>
        </div>
      </div>
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          status: statusId ? Number(statusId) : 3,
          fromDate: format(
            new Date(new Date().getFullYear(), 0, 1),
            'yyyy-MM-dd'
          ),
          toDate: format(new Date(), 'yyyy-MM-dd'),
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formApi = form;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${styles.formGrid}`}>
                <Field
                  name='fromDate'
                  type='text'
                  label='From Date'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='toDate'
                  type='text'
                  label='To Date'
                  validate={requiredField}
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />

                <Field
                  name='status'
                  label='Status'
                  options={ediStatusOptions}
                  optionMethod={(options: { label: string; value: number }[]) =>
                    options.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))
                  }
                  validate={requiredField}
                  component={FieldBSRenderSelect}
                />
                <Button type='submit' variant='primary' size='sm'>
                  {submitting ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Search'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <EdiHistoryTable
        transactions={transactions}
        openEditModal={openEditModal}
      />
      <EditEdiModal
        show={showEdit}
        handleCloseModal={closeEditModal}
        transaction={selectedTransaction}
        getTransactions={() => formApi.submit()}
      />
    </PageScaffold>
  );
}
