import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ExcessCarrierPolicy } from '../ApiTypes/ExcessCarrierPolicy';
import { ExcessCarrierPolicyRequest } from '../ApiTypes/ExcessCarrierPolicyRequest';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';

class ExcessCarrierPolicyApi {
  static getById(policyId: number) {
    return axios.get<ExcessCarrierPolicy>(
      `${API_URL}/api/excesscarrierpolicies/${policyId}`,
      getAuthHeader()
    );
  }
  static createUpdate(request: ExcessCarrierPolicyRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicies`,
      request,
      getAuthHeader()
    );
  }
  static delete(excessCarrierPolicyId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicies/${excessCarrierPolicyId}`,
      getAuthHeader()
    );
  }
  static checkExistingExcessCarrierPolicy(request: ExcessCarrierPolicyRequest) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/excesscarrierpolicies/checkexisting`,
      request,
      getAuthHeader()
    );
  }
  static getExcessPolicyList() {
    return axios.get<ExcessCarrierPolicy[]>(
      `${API_URL}/api/excesspolicy/list`,
      getAuthHeader()
    );
  }
}

export default ExcessCarrierPolicyApi;
