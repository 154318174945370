import React from 'react';
import { Button } from 'react-bootstrap';
import { LetterLogoOptions } from '../../../ApiTypes/LetterLogoOptions';
import { useAppSelector } from '../../../Reducers/Store';
import styles from './index.module.css';

export default function EmailLetterPageTwo({
  handleSelection,
}: {
  handleSelection: (value: number) => void;
}) {
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  return (
    <div>
      <h4>What type of letter would you like to send?</h4>
      <div className={`${styles.letterOptionButtons}`}>
        <Button
          type='button'
          variant='outline-primary'
          size='lg'
          onClick={() => handleSelection(LetterLogoOptions.GASLogo)}
        >
          GAS Logo Header
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='lg'
          onClick={() => handleSelection(LetterLogoOptions.NoLogo)}
        >
          Empty Logo Header
        </Button>
        <Button
          type='button'
          variant='outline-primary'
          size='lg'
          onClick={() => handleSelection(LetterLogoOptions.AccountLogo)}
          disabled={
            claim?.insurcoid !== 4 &&
            claim?.insurcoid !== 7 &&
            claim?.insurcoid !== 6
          }
        >
          Account Header
        </Button>
      </div>
    </div>
  );
}
