import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { SiteFunctionsEnum } from '../../ApiTypes/SiteFunctions';
import { ClaimTabsEnum } from '../../ApiTypes/ClaimTypeTab';
import { useAppSelector } from '../../Reducers/Store';
import RequireAuth from '../Auth/RequireAuth';
import ErrorBoundary from '../Common/ErrorBoundary';
import DiaryTab from '../TabsCustom/Diary/DiaryTab';
import DocumentsTab from '../TabsCustom/Documents/DocumentsTab';
import FroiTab from '../TabsCustom/FROI/FroiTab';
import IndemnityPaymentsTab from '../TabsCustom/IndemnityPayments/IndemnityPaymentsTab';
import InformationTab from '../TabsCustom/Information/InformationTab';
import LegalTab from '../TabsCustom/Legal/LegalTab';
import LettersTab from '../TabsCustom/Letters/LettersTab';
import Ncm from '../TabsCustom/NCM/Ncm';
import NotesTab from '../TabsCustom/Notes/NotesTab';
import PaymentsTab from '../TabsCustom/Payments/PaymentsTab';
import PhysiciansTab from '../TabsCustom/Physicians/PhysiciansTab';
import RecoveriesTab from '../TabsCustom/Recoveries/RecoveriesTab';
import ReservesTab from '../TabsCustom/Reserves/ReservesTab';
import ReviewTab from '../TabsCustom/Review/ReviewTab';
import SbwcTab from '../TabsCustom/SBWC/SbwcTab';
import WorkStatusTab from '../TabsCustom/WorkStatus/WorkStatusTab';
import styles from './index.module.css';
import { UserHistory } from '../../ApiTypes/UserHistory';
import UserApi from '../../Api/UserApi';
import WitnessesTab from '../TabsCustom/Witnesses/WitnessesTab';
import PropertyInfoTab from '../TabsCustom/PropertyInfo/PropertyInfoTab';
import CMSInfoTab from '../TabsCustom/CMSInfo/CMSInfoTab';
import SubrogationTab from '../TabsCustom/Subrogation/SubrogationTab';
import LitigationTab from '../TabsCustom/Litigation/LitigationTab';

export default function TabBarCustom({
  showClaimantForm,
  setShowClaimantForm,
  showEmployerSelect,
  setShowEmployerSelect,
  claimType,
}: {
  showClaimantForm: boolean;
  setShowClaimantForm: (show: boolean) => void;
  showEmployerSelect: boolean;
  setShowEmployerSelect: (show: boolean) => void;
  claimType: number;
}) {
  let location = useLocation();
  const { claimNumber } = useParams();
  const [key, setKey] = useState<string>('information');

  const { userModel } = useAppSelector((state) => state.user);
  const { claimTypeTab } = useAppSelector((state) => state.currentClaimReducer);

  useEffect(() => {
    locationToEventKey();
  }, [location]);

  useEffect(() => {
    addUserLog();
  }, [claimNumber]);

  const addUserLog = () => {
    if (claimNumber) {
      const history: UserHistory = {
        id: 0,
        userId: null,
        claimantName: null,
        claimNo: claimNumber,
        dateLogged: null,
        url: `/claims/${claimNumber}`,
        count: 0,
        claimTypeId: 0,
      };

      UserApi.addUserHistory(history)
        .then((res) => {
          if (!res.data.success) {
            console.log(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const locationToEventKey = () => {
    const split = location.pathname.split('/');
    if (split.length === 3 || split[3] === 'information') {
      setKey('information');
    } else {
      setKey(split[3]);
    }
  };

  return (
    <div className={`${styles.tabWidth} p-1`}>
      <Tab.Container
        defaultActiveKey='information'
        activeKey={key}
        id='client-tabs'
        onSelect={(k) => setKey(k ?? '')}
      >
        <Nav variant='tabs' className='mb-3'>
          <Nav.Item>
            <NavLink to='information'>
              <Nav.Link as='button' eventKey='information'>
                Info
              </Nav.Link>
            </NavLink>
          </Nav.Item>
          {claimNumber && claimTypeTab && (
            <>
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Notes && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Notes && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='notes'>
                      <Nav.Link as='button' eventKey='notes'>
                        Notes
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Diary && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.DiaryTab && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='diary'>
                      <Nav.Link as='button' eventKey='diary'>
                        Diary
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Payments && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Payments && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='payments'>
                      <Nav.Link as='button' eventKey='payments'>
                        Payments
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Reserves && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Reserves && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='reserves'>
                      <Nav.Link as='button' eventKey='reserves'>
                        Reserves
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Indemnity && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.IndemnityPayments &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='indemnity-payments'>
                      <Nav.Link as='button' eventKey='indemnity-payments'>
                        Indemnity
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.NCM && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) => f.siteFunctionId === SiteFunctionsEnum.Ncm && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='ncm'>
                      <Nav.Link as='button' eventKey='ncm'>
                        NCM
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Legal && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Legal && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='legal/attorneys'>
                      <Nav.Link as='button' eventKey='legal'>
                        Legal
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Litigation && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.LitigationTab &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='litigation'>
                      <Nav.Link as='button' eventKey='litigation'>
                        Litigation
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Recoveries && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Recoveries &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='recoveries'>
                      <Nav.Link as='button' eventKey='recoveries'>
                        Recoveries
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Letters && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Letters && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='letters'>
                      <Nav.Link as='button' eventKey='letters'>
                        Letters
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Documents && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.ScannedDocuments &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='scanned-documents'>
                      <Nav.Link as='button' eventKey='scanned-documents'>
                        Documents
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.SBWC && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.SwbcReporting &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='sbwc-reporting'>
                      <Nav.Link as='button' eventKey='sbwc-reporting'>
                        SBWC
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.FROI && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.FroiInfo && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='froi-reporting'>
                      <Nav.Link as='button' eventKey='froi-reporting'>
                        FROI
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Review && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.ClaimReview &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='claim-review'>
                      <Nav.Link as='button' eventKey='claim-review'>
                        Review
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Physicians && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.Physicians &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='physicians'>
                      <Nav.Link as='button' eventKey='physicians'>
                        Physicians
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.WorkStatus && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.WorkStatus &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='work-status'>
                      <Nav.Link as='button' eventKey='work-status'>
                        Work Status
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.Witnesses && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.WitnessesTab &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='witnesses'>
                      <Nav.Link as='button' eventKey='witnesses'>
                        Witnesses
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.ClaimProperty && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.ClaimPropertyTab &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='propertyinfo'>
                      <Nav.Link as='button' eventKey='propertyinfo'>
                        Property Info
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) => f.claimTabId === ClaimTabsEnum.ClaimCMSInfo && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId === SiteFunctionsEnum.ClaimCMSInfoTab &&
                    f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='cms-info'>
                      <Nav.Link as='button' eventKey='cms-info'>
                        CMS Info
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
              {claimTypeTab.some(
                (f) =>
                  f.claimTabId === ClaimTabsEnum.ClaimSubrogation && f.visible
              ) &&
                userModel?.userSiteFunctions?.some(
                  (f) =>
                    f.siteFunctionId ===
                      SiteFunctionsEnum.ClaimSubrogationTab && f.enabled
                ) && (
                  <Nav.Item>
                    <NavLink to='subrogation'>
                      <Nav.Link as='button' eventKey='subrogation'>
                        Subrogation
                      </Nav.Link>
                    </NavLink>
                  </Nav.Item>
                )}
            </>
          )}
        </Nav>
        <Routes>
          <Route
            path='*'
            element={
              <Tab.Content>
                <Tab.Pane eventKey='information'>
                  <ErrorBoundary>
                    <InformationTab
                      showClaimantForm={showClaimantForm}
                      showEmployerSelect={showEmployerSelect}
                      setShowClaimantForm={setShowClaimantForm}
                      setShowEmployerSelect={setShowEmployerSelect}
                      claimType={claimType}
                    />
                  </ErrorBoundary>
                </Tab.Pane>
              </Tab.Content>
            }
          />
          <Route
            path='notes'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Notes}>
                <Tab.Content>
                  <Tab.Pane eventKey='notes'>
                    <ErrorBoundary>
                      <NotesTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='diary'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.DiaryTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='diary'>
                    <ErrorBoundary>
                      <DiaryTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='payments'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Payments}>
                <Tab.Content>
                  <Tab.Pane eventKey='payments'>
                    <ErrorBoundary>
                      <PaymentsTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='reserves'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Reserves}>
                <Tab.Content>
                  <Tab.Pane eventKey='reserves'>
                    <ErrorBoundary>
                      <ReservesTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='indemnity-payments'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.IndemnityPayments}>
                <Tab.Content>
                  <Tab.Pane eventKey='indemnity-payments'>
                    <ErrorBoundary>
                      <IndemnityPaymentsTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='ncm/*'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Ncm}>
                <Tab.Content>
                  <Tab.Pane eventKey='ncm'>
                    <ErrorBoundary>
                      <Ncm claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='legal/*'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Legal}>
                <Tab.Content>
                  <Tab.Pane eventKey='legal'>
                    <ErrorBoundary>
                      <LegalTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='litigation'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.LitigationTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='litigation'>
                    <ErrorBoundary>
                      <LitigationTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='recoveries'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Recoveries}>
                <Tab.Content>
                  <Tab.Pane eventKey='recoveries'>
                    <ErrorBoundary>
                      <RecoveriesTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='letters'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Letters}>
                <Tab.Content>
                  <Tab.Pane eventKey='letters'>
                    <ErrorBoundary>
                      <LettersTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='scanned-documents/*'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.ScannedDocuments}>
                <Tab.Content>
                  <Tab.Pane eventKey='scanned-documents'>
                    <ErrorBoundary>
                      <DocumentsTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='sbwc-reporting'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.SwbcReporting}>
                <Tab.Content>
                  <Tab.Pane eventKey='sbwc-reporting'>
                    <ErrorBoundary>
                      <SbwcTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='froi-reporting'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.FroiInfo}>
                <Tab.Content>
                  <Tab.Pane eventKey='froi-reporting'>
                    <ErrorBoundary>
                      <FroiTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='claim-review/*'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.ClaimReview}>
                <Tab.Content>
                  <Tab.Pane eventKey='claim-review'>
                    <ErrorBoundary>
                      <ReviewTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='physicians/*'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.Physicians}>
                <Tab.Content>
                  <Tab.Pane eventKey='physicians'>
                    <ErrorBoundary>
                      <PhysiciansTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='work-status/:docid'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.WorkStatus}>
                <Tab.Content>
                  <Tab.Pane eventKey='work-status'>
                    <ErrorBoundary>
                      <WorkStatusTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='work-status/'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.WorkStatus}>
                <Tab.Content>
                  <Tab.Pane eventKey='work-status'>
                    <ErrorBoundary>
                      <WorkStatusTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='witnesses'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.WitnessesTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='witnesses'>
                    <ErrorBoundary>
                      <WitnessesTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='propertyinfo'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.ClaimPropertyTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='propertyinfo'>
                    <ErrorBoundary>
                      <PropertyInfoTab claimType={claimType} />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='cms-info'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.ClaimCMSInfoTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='cms-info'>
                    <ErrorBoundary>
                      <CMSInfoTab />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
          <Route
            path='subrogation'
            element={
              <RequireAuth siteFunction={SiteFunctionsEnum.ClaimSubrogationTab}>
                <Tab.Content>
                  <Tab.Pane eventKey='subrogation'>
                    <ErrorBoundary>
                      <SubrogationTab />
                    </ErrorBoundary>
                  </Tab.Pane>
                </Tab.Content>
              </RequireAuth>
            }
          />
        </Routes>
      </Tab.Container>
    </div>
  );
}
