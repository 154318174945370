export const EmailTemplateOptions = [
  'Adjuster_Email',
  'Adjuster_FirstName',
  'Adjuster_LastName',
  'Adjuster_Phone',
  'Claim_ClaimNo',
  'Claimant_FirstName',
  'Claimant_LastName',
  'Claimant_CellPhone',
  'Claimant_HomePhone',
  'Claimant_WorkPhone',
  'Claimant_Email',
];
