import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { DocumentType } from '../../../ApiTypes/DocumentType';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ExpandedState,
  ColumnFiltersState,
  RowData,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  FaBan,
  FaCaretDown,
  FaCaretRight,
  FaCheck,
  FaCircle,
  FaEdit,
  FaEnvelope,
  FaExpand,
  FaEye,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaFolderOpen,
  FaPlayCircle,
  FaPlusCircle,
  FaPrint,
  FaTrash,
  FaVoicemail,
} from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.css';
import { displayDateOnly, downloadZip } from '../../../Utils';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import ScanDocsLogSimpleTable from './ScanDocsLogSimpleTable';
import IndeterminateCheckbox from '../../Common/IndeterminateCheckbox';
import SecureShareModal from '../../SecureShare/SecureShareModal';
import { SecureShareDocumentRequest } from '../../../ApiTypes/SecureShareDocumentRequest';
import DeleteConfirmationModal from '../Legal/DeleteConfirmationModal';
import DocumentsApi from '../../../Api/DocumentsApi';
import { ToastContentProps, toast } from 'react-toastify';
import AddFile from '../../AddFile/AddFile';
import { AddFileFormType } from '../../AddFile/AddFileFormType';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import EmailDocumentsModal from '../../EmailDocuments/EmailDocumentsModal';
import ViewScanDocNotesRequest from '../../ViewScanDocNotesRequest/ViewScanDocNotesRequest';
import { ScanDocNotesRequest } from '../../../ApiTypes/ScanDocNotesRequest';
import RequestDocNotesModal from '../../RequestDocNotes/RequestDocNotesModal';
import { ScanDocNotesRequestFormType } from '../../../Types/ScanDocNotesRequestFormType';
import QuickClaimSearch from './QuickClaimSearch';
import { DocumentClaimNumberChangeRequest } from '../../../ApiTypes/DocumentClaimNumberChangeRequest';
import { DocumentUpdateStatusRequest } from '../../../ApiTypes/DocumentUpdateStatusRequest';
import { ClaimSearchResult } from '../../../ApiTypes/ClaimSearchResult';
import {
  setShowDocView,
  setDocViewFileDownload,
} from '../../../Actions/DocViewActions';
import { FileDownload } from '../../../Types/FileDownLoad';
import EditDocumentProperties from '../../EditDocumentProperties/EditDocumentProperties';
import EditableDateCell from './EditableDateCell';
import { UpdateScanDocRequest } from '../../../ApiTypes/UpdateScanDocRequest';
import EditableTextCell from './EditableTextCell';
import EditableDocTypeCell from './EditableDocTypeCell';
import EditableDocPrioritiesCell from './EditableDocPrioritiesCell';
import EditableDocStatusCell from './EditableDocStatusCell';
import { useDropzone } from 'react-dropzone';
import { MdMessage } from 'react-icons/md';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateDocumentRow?: (docId: number, doc: ScanDocSimple) => void;
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    updateRowDocumentProperties?: (
      rowIndex: number,
      statusChange?: string
    ) => void;
    enableMultiple?: boolean;
  }
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export default function DocumentsTable({
  docs,
  setDocs,
  getScanDocsForClaim,
  type,
  claimType,
}: {
  docs: ScanDocSimple[];
  setDocs: (
    docs: ScanDocSimple[] | ((docs: ScanDocSimple[]) => ScanDocSimple[])
  ) => void;
  type: number;
  getScanDocsForClaim: (type: number) => void;
  claimType: number;
}) {
  let { claimNumber } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { userModel } = useAppSelector((state) => state.user);
  const [data, setData] = useState<ScanDocSimple[]>(docs);
  const [original, setOriginal] = useState<ScanDocSimple[]>(docs);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [rowSelection, setRowSelection] = React.useState({});
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const CheckScanDocAccess = (docs: ScanDocSimple[]) => {
    if (userModel?.user?.isAdmin) return docs;
    return docs.filter(
      (x) =>
        x.isAdmin === false ||
        (x.isAdmin === true &&
          x.uploadedBy.toLowerCase() === userModel?.user?.userId.toLowerCase())
    );
  };

  const tableData = useMemo(() => data, [data]);

  const [selectedScanDocs, setSelectedScanDocs] = useState<ScanDocSimple[]>([]);
  const [selectedScanDoc, setSelectedScanDoc] = useState<ScanDocSimple | null>(
    null
  );
  const [showSecureShare, setShowSecureShare] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showAddFile, setShowAddFile] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [showNotesRequests, setShowNotesRequests] = useState<boolean>(false);
  const [showEditDocumentProperties, setShowEditDocumentProperties] =
    useState(false);

  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [selectedNoteRequest, setSelectedNoteRequest] =
    useState<ScanDocNotesRequest | null>(null);
  const [showRequestDocNote, setShowRequestDocNote] = useState<boolean>(false);
  const [showQuickClaimSearch, setShowQuickClaimSearch] =
    useState<boolean>(false);

  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');
  const [toEmailAddresses, setToEmailAddresses] = useState<string[]>([]);

  //
  const [enableMultiple, setEnableMultiple] = useState<boolean>(false);

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  // const [lastOpenedDoc, setLastOpenedDoc] = useState<string>('');

  const { claim, claimant, insuranceCompany } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  const { docStatuses, docPriorities, docTypes } = useAppSelector(
    (state) => state.reference
  );

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setSelectedScanDocs(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  const setShow = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEditProperties = (doc: ScanDocSimple) => {
    setSelectedScanDoc(doc);
    setShowEditDocumentProperties(true);
  };

  const options = ({ scanDoc }: { scanDoc: ScanDocSimple }) => {
    return (
      <Popover id={`${scanDoc.docid}`}>
        <Popover.Header as='h3'>{scanDoc.note}</Popover.Header>
        <Popover.Body>
          <div className={`${styles.optionsDiv}`}>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View'
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                document.body.click();
                window.open(
                  `/scandocs/${scanDoc.docid}`,
                  `newwindow${scanDoc.docid.toString()}`,
                  'width=750,height=750'
                );
              }}
            >
              <FaEye />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Edit Properties'
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                handleEditProperties(scanDoc);
                document.body.click();
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              title='Delete'
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                setShowDelete(true);
                document.body.click();
              }}
            >
              <FaTrash />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='print'
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                handleSinglePrinting(scanDoc);
                document.body.click();
              }}
            >
              <FaPrint />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Email'
              onClick={() => {
                setSelectedScanDocs([scanDoc]);
                setShowEmail(true);
                document.body.click();
              }}
            >
              <FaEnvelope />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Text'
              onClick={() => {
                document.body.click();
                window.open(
                  `/text-conversation/claimant/${claimant?.claimantId}/insurco/${insuranceCompany?.insurcoid}/scandocId/${scanDoc.docid}`,
                  `newwindow${claimant?.claimantId}`,
                  'width=450,height=560'
                );
              }}
            >
              <MdMessage />
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='View Notes'
              disabled={scanDoc.allScanDocNotesRequests < 1}
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                setShowNotesRequests(true);
                document.body.click();
              }}
            >
              View Notes
            </Button>
            {scanDoc.scanDocNotesRequests === 0 && (
              <Button
                type='button'
                variant='primary'
                size='sm'
                title='Request Notes'
                onClick={() => {
                  setSelectedScanDoc(scanDoc);
                  setSelectedNoteRequest(null);
                  setShowRequestDocNote(true);
                  document.body.click();
                }}
              >
                Req Notes
              </Button>
            )}
            {scanDoc.scanDocNotesRequests > 0 && (
              <Button
                type='button'
                variant='info'
                size='sm'
                title='Recieved Notes'
                onClick={() => {
                  handleRecievedNotes(scanDoc);
                }}
              >
                Recieved Notes
              </Button>
            )}
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Wrong Claim'
              onClick={() => {
                setSelectedScanDoc(scanDoc);
                setShowQuickClaimSearch(true);
                document.body.click();
              }}
            >
              Wrong Claim
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Send to straight pay'
              onClick={() => {
                if (claim?.status === 'I') {
                  toast.info(
                    'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                  );
                  document.body.click();
                  return;
                }
                handleStraightPay(scanDoc);
                document.body.click();
              }}
            >
              Straight Pay
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Send to bill review'
              onClick={() => {
                if (claim?.status === 'I' || claim?.status === 'IO') {
                  toast.info(
                    'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                  );
                  document.body.click();
                  return;
                }
                handleBillReview(scanDoc);
                document.body.click();
              }}
            >
              Bill Review
            </Button>
            <Button
              type='button'
              variant='primary'
              size='sm'
              title='Approve'
              onClick={() => {
                handleApprove(scanDoc);
                document.body.click();
              }}
            >
              Approve
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FaFilePdf />;
      case 'word':
        return <FaFileWord />;
      case 'excel':
        return <FaFileExcel />;
      case 'play':
        return <FaPlayCircle />;
      case 'voice':
        return <FaVoicemail />;
      default:
        return <FaFilePdf />;
    }
  };

  const getBackGroundColor = (r: ScanDocSimple) => {
    if (r.isAdmin) {
      return 'salmon';
    }
    if (r.scanDocNotesRequests > 0) {
      return 'yellow';
    }
  };

  const columnData: ColumnDef<ScanDocSimple>[] = [
    {
      header: ({ table }) => (
        <div className={`${styles.actionColumn}`}>
          {Object.keys(rowSelection).length}
          {enableMultiple && (
            <IndeterminateCheckbox
              className='form-check-input'
              {...{
                id: 'totalDocsSelected',
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
      id: 'options',
      enableSorting: false,
      enableColumnFilter: false,
      minSize: 100,
      cell: ({ row }) => {
        if (
          row.original.isAdmin === false ||
          (row.original.isAdmin === true &&
            row.original.uploadedBy.toLowerCase() ===
              userModel?.user?.userId.toLowerCase())
        ) {
          return (
            <div
              className={`${styles.actionColumn}  ${
                row.getIsSelected() ? 'orange' : ''
              } ${getBackGroundColor(row.original)} ${
                row.original.scanDocNotesRequests > 0
                  ? styles.hasPendingNoteRequests
                  : ''
              }`}
            >
              {enableMultiple && (
                <IndeterminateCheckbox
                  className='form-check-input'
                  {...{
                    id: row.id,
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              )}
              {!enableMultiple && (
                <div className='d-flex gap1Rem'>
                  {!row.getIsSelected() && (
                    <div className={`${styles.optionsDiv}`}>
                      <Button
                        type='button'
                        size='sm'
                        variant='outline-primary'
                        disabled={Object.keys(rowSelection).length > 0}
                        onClick={() => {
                          if (Object.keys(rowSelection).length === 0) {
                            setSelectedScanDoc(row.original);
                            row.toggleSelected(true);
                            window.open(
                              `/scandocs/${row.original.docid}`,
                              `newwindow${row.original.docid.toString()}`,
                              'width=750,height=750'
                            );
                            return false;
                          }
                        }}
                      >
                        <FaEdit />
                      </Button>
                      <Button
                        type='button'
                        variant='primary'
                        size='sm'
                        title='View'
                        onClick={() => {
                          setSelectedScanDoc(row.original);
                          document.body.click();
                          window.open(
                            `/scandocs/${row.original.docid}`,
                            `newwindow${row.original.docid.toString()}`,
                            'width=750,height=750'
                          );
                        }}
                      >
                        <FaEye />
                      </Button>
                    </div>
                  )}
                  {row.getIsSelected() && (
                    <>
                      <Button
                        type='button'
                        size='sm'
                        variant='outline-success'
                        title='Save'
                        onClick={() => {
                          table.options.meta?.updateRowDocumentProperties!(
                            row.index
                          );
                        }}
                      >
                        <FaCheck />
                      </Button>
                      <Button
                        type='button'
                        size='sm'
                        variant='outline-danger'
                        title='Cancel'
                        onClick={() => {
                          row.toggleSelected(false);
                          setRowSelection({});
                          setData(original);
                        }}
                      >
                        <FaBan />
                      </Button>
                    </>
                  )}
                </div>
              )}
              {Object.keys(rowSelection).length > 0 ? (
                <Button
                  type='button'
                  size='sm'
                  variant='primary'
                  title='View'
                  disabled
                >
                  <FaExpand />
                </Button>
              ) : (
                <OverlayTrigger
                  trigger='click'
                  placement='right'
                  rootClose
                  overlay={options({ scanDoc: row.original })}
                >
                  <Button
                    type='button'
                    size='sm'
                    variant='primary'
                    title='View'
                  >
                    <FaExpand />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          );
        } else {
          return (
            <div
              className={`${styles.actionColumn}  ${
                row.getIsSelected() ? 'orange' : ''
              } ${getBackGroundColor(row.original)} ${
                row.original.scanDocNotesRequests > 0
                  ? styles.hasPendingNoteRequests
                  : ''
              }`}
            ></div>
          );
        }
      },
    },
    {
      header: '',
      id: 'expander',
      size: 50,
      cell: ({ row }) => (
        <div className='d-flex gap1Rem'>
          {row.getCanExpand() ? (
            <Button
              size='sm'
              variant='outline-primary'
              title='Expand'
              onClick={row.getToggleExpandedHandler()}
            >
              {row.getIsExpanded() ? <FaCaretDown /> : <FaCaretRight />}
            </Button>
          ) : (
            <FaCircle />
          )}
        </div>
      ),
    },
    {
      header: 'Date Added',
      accessorKey: 'date',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) => displayDateOnly(d.row.original.date ?? ''),
      maxSize: 100,
    },
    {
      header: 'SVC From',
      accessorKey: 'serviceFrom',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: EditableDateCell,
      meta: {
        selectedIndex: 1,
      },
    },
    {
      header: 'SVC To',
      accessorKey: 'serviceTo',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: EditableDateCell,
      // maxSize: 100,
      meta: {
        selectedIndex: 2,
      },
    },
    {
      header: 'Icon',
      accessorKey: 'docIcon',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: (d) => getIcon(d.row.original.docIcon),
      maxSize: 50,
    },
    {
      header: 'Description',
      accessorKey: 'note',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      minSize: 300,
      cell: EditableTextCell,
    },
    {
      header: 'Doc Type',
      accessorKey: 'docType',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: EditableDocTypeCell,
      minSize: 150,
    },
    {
      header: 'Priority',
      accessorKey: 'priority',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      // maxSize: 100,
      cell: EditableDocPrioritiesCell,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      cell: EditableDocStatusCell,
      size: 200,
    },
    {
      header: 'Control #',
      accessorKey: 'compIqNum',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
  ];

  const columns = useMemo(
    () => columnData,
    [rowSelection, enableMultiple, selectedScanDoc, claim]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
      expanded,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
    getRowCanExpand: (row) =>
      row.original.documentLogs !== null &&
      row.original.documentLogs.length > 0,
    enableMultiRowSelection: enableMultiple,
    autoResetPageIndex,
    meta: {
      updateDocumentRow: (docId, doc) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old: ScanDocSimple[]) =>
          old.map((row, index) => {
            if (row.docid === docId) {
              return doc;
            }
            return row;
          })
        );
      },
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex();
        setData((old: ScanDocSimple[]) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      updateRowDocumentProperties: (rowIndex, statusChange) => {
        skipAutoResetPageIndex();
        var values = data[rowIndex];
        // console.log(values);
        // const changedStatusesList = [
        //   'Change -> Sent To Straight Pay',
        //   'Change -> Sent to Bill Review',
        //   'Change -> Accepted',
        // ];
        var request: UpdateScanDocRequest = {
          docId: values.docid,
          serviceFrom: values.serviceFrom,
          serviceTo: values.serviceTo,
          docTypeId:
            docTypes.find((x) => x.description === values.docType)?.docTypeId ??
            0,
          priorityId:
            docPriorities.find((x) => x.name === values.priority)
              ?.keyDocPriorityId ?? 0,
          notes: values.note ?? '',
          isAdmin: values.isAdmin ?? false,
        };
        return DocumentsApi.updateScanDocProperties(
          request,
          values.claimno ?? ''
        )
          .then((res) => {
            if (res.data.success) {
              if (statusChange) {
                if (statusChange === 'Change -> Sent To Straight Pay') {
                  if (claim?.status === 'I') {
                    toast.info(
                      'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                    );
                  } else {
                    return handleStraightPay(values);
                  }
                }
                if (statusChange === 'Change -> Sent to Bill Review') {
                  if (claim?.status === 'I' || claim?.status === 'IO') {
                    toast.info(
                      'You cannot request/create payments on Incident Only Claims.  Please change the status to allow this.'
                    );
                  } else {
                    return handleBillReview(values);
                  }
                }
                if (statusChange === 'Change -> Accepted') {
                  return handleApprove(values);
                }
              }
              toast.success('Updated');
              setDocs(data);
              setOriginal(data);
              if (+request.docTypeId! === DocumentType.WorkStatus) {
                navigate(`/claims/${values.claimno}/work-status`);
              }
              // setRowSelection({});
              return true;
            } else {
              toast.error(res.data.message);
              return false;
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error('Failed to update scan doc');
            return false;
          });
      },
      enableMultiple,
    },
  });

  const Msg = ({ closeToast, toastProps }: Partial<ToastContentProps>) => (
    <div>
      {(toastProps?.data as { text: string }).text}
      <div className='d-flex justify-content-around align-items-center my-3'>
        <Button
          type='button'
          variant='outline-light'
          size='sm'
          onClick={() => {
            closeToast && closeToast();
            navigate(
              `/claims/${claimNumber!}/work-status/` +
                (toastProps?.data as { docid: number }).docid
            );
          }}
        >
          Yes
        </Button>
        <Button
          variant='outline-light'
          size='sm'
          type='button'
          onClick={() => {
            closeToast && closeToast();
          }}
        >
          No
        </Button>
      </div>
    </div>
  );

  const displayToast = (docid: number) => {
    // if (workStatusId) {
    toast(<Msg />, {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      data: {
        text: `Would you like to Edit this Work Status Form?`,
        docid: docid,
      },
    });
    // }
  };

  const handleSecureShare = (values: SecureShareDocumentRequest) => {
    console.log(values);
    return DocumentsApi.secureShareDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowSecureShare(false);
          setRowSelection({});
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const handleEmailDocuments = (values: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowEmail(false);
          setRowSelection({});
          setSelectedScanDoc(null);
          setEmailBody('');
          setEmailSubject('');
          setToEmailAddresses([]);
          getScanDocsForClaim(type);
          toast.success('Email Successfully Sent');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  const handleRecievedNotes = (values: ScanDocSimple) => {
    console.log(values);
    values.claimSeverity = '';
    return DocumentsApi.updateRecievedNotes(values)
      .then((res) => {
        if (res.data.success) {
          getScanDocsForClaim(type);
          toast.success('Success');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to Update Recieved Notes');
      });
  };

  const handleDelete = () => {
    if (claimNumber && selectedScanDoc) {
      return DocumentsApi.deleteDocument(claimNumber, selectedScanDoc.docid)
        .then((res) => {
          if (res.data.success) {
            getScanDocsForClaim(type);
            setSelectedScanDoc(null);
            setShowDelete(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to delete document');
        });
    }
    return Promise.resolve();
  };

  const handleAddDocument = (values: AddFileFormType) => {
    const { file } = values.doc;
    const {
      docTypeId,
      claimNumber,
      newName,
      action,
      serviceFrom,
      serviceTo,
      isAdmin,
    } = values;
    const fd = new FormData();
    let fileToUpload = file;
    fileToUpload = new File([file], `${file.name}`, { type: file.type });
    fd.append('docTypeId', `${docTypeId}`);
    fd.append('claimNumber', claimNumber);
    fd.append('file', fileToUpload);
    fd.append('newName', newName ?? '');
    fd.append('action', action);
    fd.append('serviceFrom', serviceFrom ?? '');
    fd.append('serviceTo', serviceTo ?? '');
    fd.append('serviceTo', serviceTo ?? '');
    fd.append('isAdmin', isAdmin ? 'true' : 'false');
    return DocumentsApi.uploadDocument(claimNumber, fd)
      .then((res) => {
        if (res.data.success) {
          setShowAddFile(false);
          handleUploadActions(
            +res.data.affectedEntityIdentifier,
            res.data.message,
            docTypeId
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to upload document');
      });
  };

  const handleUploadActions = (
    docId: number,
    action: string,
    docTypeId: number
  ) => {
    switch (action) {
      case 'Accepted':
        handleApprove({ docid: docId, doctypeid: docTypeId } as ScanDocSimple);
        break;
      case 'Sent to Bill Review':
        handleBillReview({
          docid: docId,
          doctypeid: docTypeId,
        } as ScanDocSimple);
        break;
      case 'Sent To Straight Pay':
        handleStraightPay({
          docid: docId,
          doctypeid: docTypeId,
        } as ScanDocSimple);
        break;

      default:
        if (+docTypeId === DocumentType.WorkStatus) {
          displayToast(docId);
        } else {
          getScanDocsForClaim(type);
        }
        break;
    }
  };

  const handleSinglePrinting = (doc: ScanDocSimple) => {
    DocumentsApi.getScanDocFile(doc.docid)
      .then((res) => {
        setFileDownload(res.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to get ${doc.filename} for printing`);
      });
  };

  const handleMultiplePrinting = () => {
    if (selectedScanDocs.length === 1) {
      handleSinglePrinting(selectedScanDocs[0]);
    }
    if (selectedScanDocs.length > 1) {
      const docIds = selectedScanDocs.map((x) => x.docid);
      DocumentsApi.downloadDocuments(docIds)
        .then((res) => {
          if (res.data.file) {
            downloadZip(res.data.fileName, res.data.file);
          } else {
            toast.error(res.data.fileName);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(`Failed to get documents for printing`);
        });
    }
  };

  const handleEditNoteRequestClick = (r: ScanDocNotesRequest | null) => {
    setSelectedNoteRequest(r);
    if (r) {
      setShowNotesRequests(false);
      setShowRequestDocNote(true);
    }
  };

  const handleEditSaveRequestDocNote = (
    values: ScanDocNotesRequestFormType
  ) => {
    if (values.keyNotesReqId) {
      return DocumentsApi.updateNotesRequestForDoc(
        values,
        claimNumber!,
        values.save
      )
        .then((res) => {
          if (res.data.success) {
            getScanDocsForClaim(type);
            setSelectedNoteRequest(null);
            setSelectedScanDoc(null);
            setShowRequestDocNote(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to update notes request');
        });
    }
    values.status = 0;
    return DocumentsApi.createNotesRequestForDoc(
      values,
      claimNumber!,
      values.save
    )
      .then((res) => {
        if (res.data.success) {
          getScanDocsForClaim(type);
          setSelectedNoteRequest(null);
          setSelectedScanDoc(null);
          setShowRequestDocNote(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create notes request');
      });
  };

  const handleSelectedClaimToMoveTo = (result: ClaimSearchResult) => {
    const docStatusNew = docStatuses.find((f) => f.name === 'New');

    const data: DocumentClaimNumberChangeRequest = {
      docId: selectedScanDoc?.docid ?? 0,
      docStatusId: docStatusNew?.keyDocStatusId ?? 0,
      oldClaimNumber: claimNumber!,
      newClaimNumber: result.claimNo,
    };

    return DocumentsApi.changeClaimNumber(data)
      .then((res) => {
        if (res.data.success) {
          getScanDocsForClaim(type);
          setSelectedScanDoc(null);
          setShowQuickClaimSearch(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to re-assign document with new claim number');
      });
  };

  const handleStraightPay = (doc: ScanDocSimple) => {
    setSelectedScanDoc(doc);
    const status = docStatuses.find((f) => f.name === 'Sent To Straight Pay');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docid,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: claimNumber!,
      note: 'Sent to Straight Pay',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docid });

    DocumentsApi.updateStatus(data)
      .then((res) => {
        if (!res.data.success) {
          toast.update(doc.docid, { type: 'error', render: res.data.message });
          return;
        }
        if (claim?.insurcoid === 121) {
          const subject = `New Straight Pay Bill: ${claimNumber} / ${claimant?.firstName} ${claimant?.lastName}`;
          const body = `New Straight Pay Bill attached.
Claimant Name: ${claimant?.firstName} ${claimant?.lastName}
Claim Number: ${claimNumber}
Bill Name: ${doc.note}
Doc Name: ${doc.note}`;

          setEmailBody(body);
          setEmailSubject(subject);
          setToEmailAddresses([
            'sboyle@federatedrural.com',
            'gtucker@federatedrural.com',
          ]);
          setShowEmail(true);
        } else {
          toast.update(doc.docid, { type: 'success', render: 'Success' });
          if (doc?.doctypeid === DocumentType.WorkStatus) {
            navigate(`/claims/${claimNumber!}/work-status`);
          } else {
            getScanDocsForClaim(type);
            setSelectedScanDoc(null);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(doc.docid, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const handleSetShowEmail = (show: boolean) => {
    if (!show) {
      setEmailBody('');
      setEmailSubject('');
      setToEmailAddresses([]);
      setSelectedScanDoc(null);
      setShowEmail(false);
    }
  };

  const handleBillReview = (doc: ScanDocSimple) => {
    setSelectedScanDoc(doc);
    const status = docStatuses.find((f) => f.name === 'Sent to Bill Review');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docid,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: claimNumber!,
      note: 'Sent to Bill Review',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docid });

    if (claim?.insurcoid === 121) {
      DocumentsApi.billReviewFederated(data)
        .then((res) => {
          if (res.data.success) {
            if (doc?.doctypeid === DocumentType.WorkStatus) {
              navigate(`/claims/${claimNumber!}/work-status`);
            } else {
              getScanDocsForClaim(type);
              setSelectedScanDoc(null);
            }
            toast.update(doc.docid, { type: 'success', render: 'Success' });
          } else {
            toast.update(doc.docid, {
              type: 'error',
              render: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.update(doc.docid, {
            type: 'error',
            render: 'Failed to update status',
          });
        });
    } else {
      DocumentsApi.updateStatus(data)
        .then((res) => {
          if (res.data.success) {
            if (doc?.doctypeid === DocumentType.WorkStatus) {
              navigate(`/claims/${claimNumber!}/work-status`);
            } else {
              getScanDocsForClaim(type);
              setSelectedScanDoc(null);
            }
            toast.update(doc.docid, { type: 'success', render: 'Success' });
          } else {
            toast.update(doc.docid, {
              type: 'error',
              render: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.update(doc.docid, {
            type: 'error',
            render: 'Failed to update status',
          });
        });
    }
  };

  const handleApprove = (doc: ScanDocSimple) => {
    setSelectedScanDoc(doc);
    const status = docStatuses.find((f) => f.name === 'Accepted');

    const data: DocumentUpdateStatusRequest = {
      docId: doc.docid,
      docStatusId: status?.keyDocStatusId ?? 0,
      claimNumber: claimNumber!,
      note: 'Marked Approved',
    };

    toast('Updating Status', { type: 'info', toastId: doc.docid });

    DocumentsApi.updateStatus(data)
      .then((res) => {
        if (res.data.success) {
          if (doc?.doctypeid === DocumentType.WorkStatus) {
            navigate(`/claims/${claimNumber!}/work-status`);
          } else {
            getScanDocsForClaim(type);
            setSelectedScanDoc(null);
          }
          toast.update(doc.docid, { type: 'success', render: 'Success' });
        } else {
          toast.update(doc.docid, { type: 'error', render: res.data.message });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(doc.docid, {
          type: 'error',
          render: 'Failed to update status',
        });
      });
  };

  const refresh = () => {
    getScanDocsForClaim(type);
  };

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    const fileCount = acceptedFiles.length;
    if (fileCount > 0) {
      let currentFileCount = 0;
      acceptedFiles.forEach((element) => {
        if (claimNumber) {
          console.log('Uploaded File Name: ' + element.name);
          const file = element;
          const fd = new FormData();
          let fileToUpload = file;
          fileToUpload = new File([file], `${file.name}`, { type: file.type });
          fd.append('docTypeId', '20'); //Drag And Drop
          fd.append('claimNumber', claimNumber);
          fd.append('file', fileToUpload);
          fd.append('newName', element.name ?? '');
          DocumentsApi.uploadDocument(claimNumber, fd)
            .then((res) => {
              if (res.data.success) {
                toast.success('Uploaded File: ' + element.name);
                currentFileCount++;
                if (fileCount === currentFileCount) {
                  getScanDocsForClaim(DocumentType.All);
                }
              } else {
                toast.error(res.data.message);
                currentFileCount++;
                if (fileCount === currentFileCount) {
                  getScanDocsForClaim(DocumentType.All);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error('Failed to upload document');
            });
        }
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div {...getRootProps({ onClick: (e) => e.stopPropagation() })}>
        <input {...getInputProps()} />
        <div className={`py-2 ${styles.actionColumn}`}>
          <div className='form-check text-left'>
            <label className='form-check-label'>
              <input
                id='multipleSelectInput'
                type='checkbox'
                className='form-check-input'
                checked={enableMultiple}
                onChange={(e) => {
                  const newValue = !enableMultiple;
                  if (!newValue) {
                    setRowSelection({});
                  }
                  setEnableMultiple((enableMultiple) => !enableMultiple);
                }}
                disabled={
                  Object.keys(rowSelection).length > 0 && !enableMultiple
                }
              />
              Enable Multiple Selection
            </label>
          </div>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              setSelectedScanDoc(null);
              setShowAddFile(true);
            }}
            disabled={claimNumber === undefined}
          >
            <div className='button-icon-text'>
              <FaPlusCircle /> Add Doc
            </div>
          </Button>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              setShowSecureShare(true);
            }}
            disabled={Object.keys(rowSelection).length === 0 || !enableMultiple}
          >
            <div className='button-icon-text'>
              <FaFolderOpen /> Secure Share
            </div>
          </Button>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              handleMultiplePrinting();
            }}
            disabled={Object.keys(rowSelection).length === 0 || !enableMultiple}
          >
            <div className='button-icon-text'>
              <FaPrint /> Print Docs
            </div>
          </Button>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              setSelectedScanDoc(null);
              setShowEmail(true);
            }}
            disabled={Object.keys(rowSelection).length === 0 || !enableMultiple}
          >
            <div className='button-icon-text'>
              <FaEnvelope /> Email
            </div>
          </Button>
        </div>
        <PaginatedTable
          table={table}
          columnResizeMode='onChange'
          showFilters={true}
          createChildTable={ScanDocsLogSimpleTable}
          subRowProp={'documentLogs'}
        />
      </div>
      <SecureShareModal
        show={showSecureShare}
        setShow={setShowSecureShare}
        claimNumber={claimNumber!}
        docs={selectedScanDocs}
        userEmailList={userEmailList}
        handleSecureShare={handleSecureShare}
      />
      <DeleteConfirmationModal
        show={showDelete}
        setShow={setShowDelete}
        setNull={setSelectedScanDoc}
        handleDelete={handleDelete}
      />
      <AddFile
        show={showAddFile}
        setShow={setShowAddFile}
        claimNumber={claimNumber!}
        handleAddDocument={handleAddDocument}
        claimType={claimType}
      />
      <EmailDocumentsModal
        show={showEmail}
        setShow={handleSetShowEmail}
        handleEmailDocuments={handleEmailDocuments}
        userEmailList={userEmailList}
        claimNumber={claimNumber!}
        subject={emailSubject}
        body={emailBody}
        toEmailAddresses={toEmailAddresses}
        docs={selectedScanDocs}
      />
      <ViewScanDocNotesRequest
        show={showNotesRequests}
        setShow={setShowNotesRequests}
        doc={selectedScanDoc}
        setSelectedNoteRequest={setSelectedNoteRequest}
        handleEditNoteRequestClick={handleEditNoteRequestClick}
      />
      <RequestDocNotesModal
        show={showRequestDocNote}
        setShow={setShowRequestDocNote}
        noteRequest={selectedNoteRequest}
        doc={selectedScanDoc}
        claimNumber={claimNumber!}
        claimantName={`${claimant?.firstName ?? ''} ${
          claimant?.lastName ?? ''
        }`}
        handleEditSaveRequestDocNote={handleEditSaveRequestDocNote}
      />
      <QuickClaimSearch
        show={showQuickClaimSearch}
        setShow={setShowQuickClaimSearch}
        handleSelectedClaim={handleSelectedClaimToMoveTo}
      />
      {showEditDocumentProperties && (
        <EditDocumentProperties
          show={showEditDocumentProperties}
          setShow={setShowEditDocumentProperties}
          scanDoc={null}
          scanDocSimple={selectedScanDoc}
          refresh={refresh}
          claim={claim ?? undefined}
          handleStraightPay={handleStraightPay}
          handleBillReview={handleBillReview}
          handleApprove={handleApprove}
        />
      )}
    </div>
  );
}
