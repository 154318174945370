import { useState, useEffect } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';

import styles from './index.module.css';
import { EmailTemplate } from '../../ApiTypes/EmailTemplate';
import { useAppSelector } from '../../Reducers/Store';
import { toast } from 'react-toastify';
import { FaPlusCircle } from 'react-icons/fa';
import EmailTemplateTable from './EmailTemplateMaintenance/EmailTemplateTable';
import EmailTemplateModal from './EmailTemplateMaintenance/EmailTemplateModal';
import EmailTemplateApi from '../../Api/EmailTemplateApi';

export default function EmailTemplateManageModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [emailTemplates, setemailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedTemplate, setselectedTemplate] =
    useState<EmailTemplate | null>(null);
  const [showAddEdit, setshowAddEdit] = useState<boolean>(false);

  const getEmailTemplateList = () => {
    EmailTemplateApi.getEmailTemplateListCustom(userModel?.user?.userId!, 0)
      .then((res) => {
        setemailTemplates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userModel?.user?.userId && show) {
      getEmailTemplateList();
    }
  }, [show]);

  const handleViewEdit = (template: EmailTemplate) => {
    setselectedTemplate(template);
    setshowAddEdit(true);
  };

  const handleDelete = (values: EmailTemplate) => {
    return EmailTemplateApi.deleteEmailTemplate(values.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getEmailTemplateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete');
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Email-Template-modal'
      className={styles.modalBackground}
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Email-Template-modal'>
          My Email Templates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div className='d-flex justify-content-center align-items-center pb-3'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              className='button-icon-text'
              onClick={() => {
                setselectedTemplate(null);
                setshowAddEdit(true);
              }}
            >
              <FaPlusCircle /> New
            </Button>
          </div>
          <EmailTemplateTable
            data={emailTemplates}
            handleViewEdit={handleViewEdit}
            handleDelete={handleDelete}
            isAdmin={false}
          />
          <EmailTemplateModal
            show={showAddEdit}
            setShow={setshowAddEdit}
            selectedTemplate={selectedTemplate}
            getEmailTemplateList={getEmailTemplateList}
            isAdmin={false}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
