import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Container,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Form as RFFForm, Field } from 'react-final-form';
import { FaLink } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ClaimApi from '../../Api/ClaimApi';
import { Claim } from '../../ApiTypes/Claim';
import { Claimant } from '../../ApiTypes/Claimant';
import {
  ClaimSearchRequest,
  SearchClaimStatus,
  SearchSeverity,
} from '../../ApiTypes/ClaimSearchRequest';
import { ClaimSearchResult } from '../../ApiTypes/ClaimSearchResult';
import { LinkedClaim } from '../../ApiTypes/LinkedClaim';
import { useAppSelector } from '../../Reducers/Store';
import { removeNonDigits, formatSSN } from '../../Utils/InputFormatters';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import styles from './index.module.css';
import LinkClaimSearchTable from './LinkClaimSearchTable';
import LinkedClaimTable from './LinkedClaimTable';

export default function LinkClaimModal({
  show,
  setShow,
  confirm,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  confirm: (claimNumber: string) => void;
}) {
  const [searchResults, setSearchResults] = useState<ClaimSearchResult[]>([]);
  const [newLinkedClaims, setNewLinkedClaims] = useState<LinkedClaim[]>([]);
  const [previousLinkedClaims, setPreviousLinkedClaims] = useState<
    LinkedClaim[]
  >([]);

  const [count, setCount] = useState<number>(0);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const { claim, claimant } = useAppSelector(
    (state) => state.currentClaimReducer
  );

  useEffect(() => {
    getLinkedClaims();
  }, [claim]);

  const getLinkedClaims = () => {
    if (claim?.claimNo) {
      setCount(0);
      return ClaimApi.getLinkedClaims(claim.claimNo)
        .then((res) => {
          setPreviousLinkedClaims(res.data);
          if (res.data.length === 0) {
            createOriginalLinkClaim(claim);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const createOriginalLinkClaim = (c: Claim) => {
    setCount(1);
    const original: LinkedClaim = {
      id: 0,
      originalClaimno: claim?.claimNo ?? '',
      newClaimno: `${claim?.claimNo}-1`,
      claimant: claimant,
    };
    setNewLinkedClaims([original]);
  };

  const search = (values: ClaimSearchRequest) => {
    return ClaimApi.searchClaims(values)
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {
        setSearchResults([]);
        console.log(err);
      });
  };

  const handleSelect = (linkedClaim: LinkedClaim) => {
    if (claim?.claimNo) {
      let newClaimNo = `${claim?.claimNo}-1`;
      if (linkedClaim.newClaimno === newClaimNo) {
        toast.error('Can not remove original claim when linking');
        return;
      }
    }
    const lc = newLinkedClaims.filter(
      (c) =>
        c.originalClaimno !== linkedClaim.originalClaimno &&
        c.newClaimno !== linkedClaim.newClaimno
    );
    setNewLinkedClaims(lc);
  };
  const getSelectedClaim = (claimSearchResult: ClaimSearchResult) => {
    if (claim?.claimNo) {
      const parts = claim?.claimNo.split('-');
      let newCount = count + 1;
      if (parts.length > 3) {
        newCount = +parts[3] + 1;
        setCount(newCount);
      } else {
        setCount(newCount);
      }
      const newClaimNo = `${parts[0]}-${parts[1]}-${parts[2]}-${newCount}`;
      const newClaimant: Claimant = {
        claimantId: 0,
        lastName: claimSearchResult.lastName,
        firstName: claimSearchResult.firstName,
        middleName: null,
        ssn: null,
        dob: null,
        currentEmployerId: null,
        sex: null,
        employmentDate: null,
        mainAddressId: null,
        paymentAddressId: null,
        homePhone: null,
        workPhone: null,
        cellPhone: null,
        usePaymentAddress: null,
        emailAddress: null,
        textCode: null,
        textCodeExpiration: null,
        enableMobileAccess: null,
        enableAch: null,
        achRoutingNumber: null,
        achBankAccountNumber: null,
        mainAddress: null,
        paymentAddress: null,
      };
      const newLinkClaim: LinkedClaim = {
        id: 0,
        originalClaimno: claimSearchResult.claimNo,
        newClaimno: newClaimNo,
        claimant: newClaimant,
      };
      setNewLinkedClaims([...newLinkedClaims, newLinkClaim]);
    }
  };

  const handleLinking = () => {
    if (newLinkedClaims.length > 0) {
      ClaimApi.linkClaims(claim?.claimNo ?? '', newLinkedClaims)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claims successfully given new claim numbers.');
            confirm(res.data.affectedEntityIdentifier);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to link claims');
        });
    }
  };

  const handleLinkClick = () => {
    if (newLinkedClaims.length > 0) {
      setShowConfirm(true);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='Confirm-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='Confirm-modal'>
          <FaLink className='pe-1' /> Link Claim
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <p className='text-center fw-bold'>
            Join multiple claimants to the same claim number
          </p>
          <div className='d-flex justify-content-around align-items-center mb-3'>
            <div>
              <span className='fw-bold'>Claim Number: </span>
              <span>{claim?.claimNo}</span>
            </div>
            <div>
              <span className='fw-bold'>Claimant: </span>
              <span>
                {claimant?.firstName} {claimant?.lastName}
              </span>
            </div>
          </div>
          <RFFForm
            onSubmit={search}
            initialValues={{
              severity: SearchSeverity.Any,
              claimStatus: SearchClaimStatus.ShowAll,
            }}
            render={({ handleSubmit, form, values, pristine, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.grid4}`}>
                    <Field
                      name='firstName'
                      type='text'
                      label='First Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='lastName'
                      type='text'
                      label='Last Name'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='claimNumber'
                      type='text'
                      label='Claim #'
                      component={FieldBSRenderText}
                    />
                    <Field
                      name='ssn'
                      type='text'
                      label='SSN'
                      parse={removeNonDigits}
                      format={formatSSN}
                      component={FieldBSRenderText}
                    />
                  </div>
                  <div className='d-flex justify-content-center align-items-center gap1Rem mb-3'>
                    <Button type='submit' variant='outline-primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Search'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='outline-primary'
                      onClick={() => {
                        form.reset();
                        setSearchResults([]);
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
          {searchResults.length > 0 && (
            <LinkClaimSearchTable
              data={searchResults}
              getSelectedClaim={getSelectedClaim}
            />
          )}
          <p className='fw-bold mt-3 mb-0'>Previously Linked Claims</p>
          <LinkedClaimTable
            data={previousLinkedClaims}
            handleSelect={undefined}
          />
          <p className='fw-bold mb-0'>Claims to Link</p>
          <LinkedClaimTable
            data={newLinkedClaims}
            handleSelect={handleSelect}
          />
          {showConfirm && (
            <Alert
              variant='danger'
              className='mt-3 text-center'
              dismissible
              onClose={() => {
                setShowConfirm(false);
              }}
            >
              <p>
                You are about to attempt to join the selected claims under the
                same claim number.
              </p>
              <p>
                Once you attempt this it will not be easy to undo these changes.
              </p>
              <p>Are you sure you would like to continue?</p>
              <div className='d-flex justify-content-center align-items-center gap1Rem'>
                <Button
                  type='button'
                  size='sm'
                  variant='primary'
                  onClick={handleLinking}
                >
                  Continue
                </Button>
                <Button
                  type='button'
                  size='sm'
                  variant='secondary'
                  onClick={() => {
                    setShowConfirm(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Alert>
          )}

          <div className='d-flex justify-content-around align-items-center my-3'>
            <Button
              type='button'
              size='sm'
              variant='primary'
              onClick={handleLinkClick}
              disabled={showConfirm}
            >
              Join Claims
            </Button>
            <Button
              type='button'
              size='sm'
              variant='secondary'
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
