import { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FormApi } from 'final-form';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { toast } from 'react-toastify';
import TextLogApi from '../../Api/TextLogApi';
import { TextLog } from '../../ApiTypes/TextLog';
import { displayDateAndTimeLocal } from '../../Utils';
import TextingConnection from './TextingConnection';
import { UserTextConversation } from '../../ApiTypes/UserTextConversation';
import { FaChevronDown, FaWindowClose } from 'react-icons/fa';
import UserApi from '../../Api/UserApi';
import { fetchUserTextConversations } from '../../Actions';

export default function NewTextingPopup({
  convo,
}: {
  convo: UserTextConversation;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [conversationData, setconversationData] = useState<TextLog[]>([]);

  let formInstance: FormApi<TextLog>;

  const [isConnected, setisConnected] = useState<boolean>(true);
  const [data, setData] = useState<TextLog[]>([]);

  const [showtext, setshowtext] = useState<boolean>(false);
  useEffect(() => {
    if (showtext && convo.claimantPhoneNumber) {
      getConversationTextList();
    }
  }, [showtext]);

  const getConversationTextList = () => {
    if (convo.claimantPhoneNumber) {
      TextLogApi.getConversationTextList(convo.claimantPhoneNumber)
        .then((res) => {
          setconversationData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setconversationData([]);
    }
  };

  const onSubmit = (values: TextLog) => {
    return TextLogApi.addOutgoingLog(values)
      .then((res) => {
        if (res.data.success) {
          formInstance.reset();
          getConversationTextList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to send text');
      });
  };

  const closeChat = (c: UserTextConversation) => {
    if (userModel && userModel.user) {
      const request: UserTextConversation = {
        id: 0,
        userId: userModel.user.userId,
        claimNumber: c.claimNumber,
        claimantName: c.claimantName,
        claimantPhoneNumber: c.claimantPhoneNumber,
        dateCreated: c.dateCreated,
      };
      console.log(request);
      return UserApi.updateUserTextConversations(request, 'remove')
        .then((res) => {
          if (res.data.success) {
            dispatch(fetchUserTextConversations(userModel.user!.userId));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error('Failed to send text');
          console.log(err);
        });
    }
  };

  return (
    <>
      {!showtext && (
        <div className={`${styles.popupMinimizedWrapper}`}>
          <div
            onClick={() => setshowtext(true)}
            className={`${styles.popupMinimized}`}
          >
            {(convo.claimantName ?? '').trim().length > 0
              ? convo.claimantName
              : 'N/A'}{' '}
            - {convo.claimantPhoneNumber}
          </div>
          <div
            onClick={() => {
              closeChat(convo);
            }}
            className={`${styles.closePopup}`}
            style={{ padding: '2px' }}
          >
            <FaWindowClose />
          </div>
        </div>
      )}
      {showtext && (
        <div className={`${styles.popup}`}>
          <div className={`${styles.textTopInfoPopup}`}>
            <div className='d-flex justify-content-between'>
              <div
                onClick={() => {
                  setshowtext(false);
                }}
                className={`${styles.closePopup}`}
              >
                <FaChevronDown />
              </div>
              <div
                onClick={() => {
                  closeChat(convo);
                }}
                className={`${styles.closePopup}`}
              >
                <FaWindowClose />
              </div>
            </div>
            <div>From Phone #: (770) 609-4545</div>
            <div>Claimant Name: {convo.claimantName}</div>
            <div>Claimant Phone #: {convo.claimantPhoneNumber}</div>
          </div>
          <div
            className={`d-flex flex-column-reverse border w-100 overflow-auto ${styles.textBackground}`}
            style={{ height: '100%' }}
          >
            <div className='d-flex flex-column'>
              {conversationData.map((text) => {
                return text.direction === 'incoming-api' ? (
                  <div key={text.id} className='d-flex flex-column'>
                    <div className={`${styles.fromMessage}`}>
                      <div className={`${styles.fromMessageBody}`}>
                        {text.body}
                        {text.textLogMedia?.map((media) => (
                          <img key={media.id} src={media.mediaUrl ?? ''} />
                        ))}
                      </div>
                    </div>
                    <div
                      key={'date-' + text.id}
                      className={`${styles.fromMessage}`}
                    >
                      <div className={`${styles.messageDate}`}>
                        {displayDateAndTimeLocal(text.dateCreated ?? '')}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={text.id} className='d-flex flex-column'>
                    <div className={`${styles.toMessage}`}>
                      <div className={`${styles.toMessageBody}`}>
                        {text.body}
                        {text.textLogMedia?.map((media) => (
                          <img key={media.id} src={media.mediaUrl ?? ''} />
                        ))}
                      </div>
                    </div>
                    <div
                      key={'date-' + text.id}
                      className={`${styles.toMessage}`}
                    >
                      <div className={`${styles.messageDate}`}>
                        {displayDateAndTimeLocal(text.dateCreated ?? '')}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <RFFForm
            onSubmit={onSubmit}
            initialValues={{
              numberTo: convo.claimantPhoneNumber,
              // firstName: claimant?.firstName,
              // lastName: claimant?.lastName,
              claimantPhoneNumber: convo.claimantPhoneNumber,
              body: '',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form
                  onSubmit={handleSubmit}
                  className={`${styles.formSubmit}`}
                >
                  <div className={`d-flex`}>
                    <div className={`w-100`}>
                      <Field
                        name='body'
                        label='Message'
                        rows={2}
                        component={FieldBSRenderTextArea}
                      />
                    </div>
                    {conversationData.length > 0 && (
                      <div className={styles.claimantFormButtonDiv}>
                        <Button type='submit' size='sm' variant='primary'>
                          {submitting ? (
                            <Spinner
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          ) : (
                            'Send'
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          />
          {/* <div className='d-flex align-items-center gap-2'>
            {conversationData.length > 0 && (
              <div
                className={`${styles.viewTemplateButton}`}
                // onClick={() => setshowTemplates(true)}
              >
                <FaClipboardList />
                View Templates
              </div>
            )}
            <div
              className={`${styles.viewTemplateButton}`}
              //   onClick={() => setshowManageTemplates(true)}
            >
              Manage Templates
            </div>
          </div> */}
        </div>
      )}
      <TextingConnection
        claimantPhoneNumber={null}
        getConversationTextList={getConversationTextList}
      />
    </>
  );
}
