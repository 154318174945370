import React, { useEffect, useState } from 'react';
import { Alert, Dropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import {
  FaHome,
  FaSearch,
  FaPlus,
  FaClock,
  FaUserCog,
  FaSignOutAlt,
} from 'react-icons/fa';
import { Form, Link, useNavigate } from 'react-router-dom';
import {
  SiteFunctionGroups,
  SiteFunctionsEnum,
} from '../../ApiTypes/SiteFunctions';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import ReportRequestModal from '../ReportRequestModal/ReportRequestModal';
import { useReportRequestModal } from '../ReportRequestModal/useReportRequestModal';
import ClaimSearch from './ClaimSearch/ClaimSearch';
import DocumentsDropdown from './Dropdowns/DocumentsDropdown';
import PaymentsDropdown from './Dropdowns/PaymentsDropdown';
import ProvidersDropdown from './Dropdowns/ProvidersDropdown';
import ReportsDropdown from './Dropdowns/ReportsDropdown';
import SetupDropdown from './Dropdowns/SetupDropdown';
import WcPanelsDropdown from './Dropdowns/WcPanelsDropdown';
import styles from './index.module.css';
import { useDxReportRequestModal } from '../DxReportRequestModal/useDxReportRequestModal';
import DxReportRequestModal from '../DxReportRequestModal/DxReportRequestModal';
import RecurringPaymentsApi from '../../Api/RecurringPaymentsApi';
import {
  ClaimTypes,
  ClaimTypesURL,
} from '../../ApiTypes/ClaimTypeConfiguration';
import { BiSolidConversation } from 'react-icons/bi';
import { Form as RFForm, Field } from 'react-final-form';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { fetchCurrentUser, setTheme } from '../../Actions';
import UserApi from '../../Api/UserApi';
import { toast } from 'react-toastify';
import TextLogApi from '../../Api/TextLogApi';
import * as signalR from '@microsoft/signalr';
import TextingConnection from '../Texting/TextingConnection';

export default function TopToolbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userModel } = useAppSelector((state) => state.user);
  const { themeTypeId } = useAppSelector((state) => state.theme);
  const [unreadLogsCount, setunreadLogsCount] = useState<number>(0);
  const [showClaimSearch, setShowClaimSearch] = useState<boolean>(false);
  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();
  const {
    showReportRequestModal,
    setShowReportRequestModal,
    reportEnumValue,
    setReportEnumValue,
  } = useReportRequestModal();
  const {
    showDxReportRequestModal,
    setShowDxReportRequestModal,
    reportEnumValueDx,
    setReportEnumValueDx,
  } = useDxReportRequestModal();

  useEffect(() => {
    setExpiringIndemnityReminders();
  }, [userModel]);

  useEffect(() => {
    getUnreadLogsCount();
  }, []);

  const logout = () => {
    localStorage.removeItem('gasToken');
    navigate('/login');
  };

  // this needs to run a stored procedure to create diaries.
  const setExpiringIndemnityReminders = () => {
    RecurringPaymentsApi.insertReminders()
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'themeTypeId') {
      handleUpdateTheme(Number(value));
    }
  };
  const handleUpdateTheme = async (themeId: number) => {
    await UserApi.updateThemeId(userModel?.user?.userId ?? '', themeId)
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchCurrentUser());
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update email address');
        return false;
      });
    window.location.reload();
  };

  const getUnreadLogsCount = async () => {
    await TextLogApi.getUnreadLogsCount()
      .then((res) => {
        setunreadLogsCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {process.env.REACT_APP_DEPLOY_ENV === 'dev' && (
        <Alert variant='warning' className='m-0'>
          <Alert.Heading>DEV ENVIRONMENT</Alert.Heading>
        </Alert>
      )}
      {process.env.REACT_APP_DEPLOY_ENV === 'gl' && (
        <Alert variant='warning' className='m-0'>
          <Alert.Heading>GENERAL LIABILITY ENVIRONMENT</Alert.Heading>
        </Alert>
      )}
      <ButtonToolbar
        className={`${styles.topToolbar} text-bg-secondary p-2`}
        aria-label='Toolbar with buttons'
      >
        <Link to='/reminders'>
          <Button variant='secondary'>
            <div className='button-icon-text'>
              <FaHome />
              Home
            </div>
          </Button>
        </Link>
        <Button onClick={() => setShowClaimSearch(true)} variant='secondary'>
          <div className='button-icon-text'>
            <FaSearch />
            Search
          </div>
        </Button>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Claims && f.enabled
        ) && (
          <Dropdown>
            <Dropdown.Toggle
              variant='secondary'
              id='newClaim'
              className='button-icon-text'
            >
              <FaPlus />
              New Claim
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to='/new-claim-requests'>
                <Dropdown.Item as='button'>New Claim Requests</Dropdown.Item>
              </Link>
              {userModel?.userClaimTypeAccesses?.some(
                (f) => f.claimTypeId === ClaimTypes.WorkersComp
              ) && (
                <Link to='/claims'>
                  <Dropdown.Item as='button'>
                    New Workers Comp Claim
                  </Dropdown.Item>
                </Link>
              )}
              {userModel?.userClaimTypeAccesses?.some(
                (f) => f.claimTypeId === ClaimTypes.GeneralLiability
              ) && (
                <Link to={`/${ClaimTypesURL[ClaimTypes.GeneralLiability]}`}>
                  <Dropdown.Item as='button'>
                    New General Liability Claim
                  </Dropdown.Item>
                </Link>
              )}
              {userModel?.userClaimTypeAccesses?.some(
                (f) => f.claimTypeId === ClaimTypes.AutomobileLiability
              ) && (
                <Link to={`/${ClaimTypesURL[ClaimTypes.AutomobileLiability]}`}>
                  <Dropdown.Item as='button'>
                    New Auto Liability Claim
                  </Dropdown.Item>
                </Link>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Documents &&
            f.enabled
        ) && <DocumentsDropdown />}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Payments && f.enabled
        ) && <PaymentsDropdown />}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Providers &&
            f.enabled
        ) && <ProvidersDropdown />}
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Reports && f.enabled
        ) && (
          <ReportsDropdown
            setReportEnumValue={setReportEnumValue}
            setShowReportRequestModal={setShowReportRequestModal}
            setReportEnumValueDx={setReportEnumValueDx}
            setShowDxReportRequestModal={setShowDxReportRequestModal}
          />
        )}
        <WcPanelsDropdown />
        <Link to='/reminders'>
          <Button variant='secondary'>
            <div className='button-icon-text'>
              <FaClock />
              Reminders
            </div>
          </Button>
        </Link>
        <Link to='/text-messages' className='relative'>
          <Button variant='secondary'>
            <div className='button-icon-text'>
              <BiSolidConversation />
              Text Messages
              {unreadLogsCount > 0 && (
                <span className={styles.textNotification}>
                  {unreadLogsCount}
                </span>
              )}
            </div>
          </Button>
        </Link>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunction?.groupId === SiteFunctionGroups.Setup && f.enabled
        ) && <SetupDropdown />}
        <Button variant='secondary' onClick={logout}>
          <div className='button-icon-text'>
            <FaSignOutAlt />
            Logout
          </div>
        </Button>
        <div className='d-flex my-auto gap-2'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='themeTypeId'
              id='themeTypeId'
              value={0}
              checked={userModel?.user?.themeId === 0}
              onChange={handleChangeInput}
            />
            <label className='form-check-label'>Light</label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='themeTypeId'
              id='themeTypeId'
              value={1}
              checked={userModel?.user?.themeId === 1}
              onChange={handleChangeInput}
            />
            <label className='form-check-label'>Grey</label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='themeTypeId'
              id='themeTypeId'
              value={2}
              checked={userModel?.user?.themeId === 2}
              onChange={handleChangeInput}
            />
            <label className='form-check-label'>Dark</label>
          </div>
        </div>
      </ButtonToolbar>
      <ClaimSearch show={showClaimSearch} setShow={setShowClaimSearch} />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
      <ReportRequestModal
        show={showReportRequestModal}
        setShow={setShowReportRequestModal}
        reportEnumValue={reportEnumValue}
        setShowDocViewModal={setShowDocViewModal}
        setFileDownload={setFileDownload}
      />
      <DxReportRequestModal
        show={showDxReportRequestModal}
        setShow={setShowDxReportRequestModal}
        reportEnumValue={reportEnumValueDx}
        setShowDocViewModal={setShowDocViewModal}
        setFileDownload={setFileDownload}
      />
      <TextingConnection
        claimantPhoneNumber={null}
        getUnreadLogsCount={getUnreadLogsCount}
      />
    </>
  );
}
