import React, { useMemo, useState } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { ExcessCarrierPolicy } from '../../ApiTypes/ExcessCarrierPolicy';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { displayDateOnly } from '../../Utils';
import accounting from 'accounting';

export default function ExcessPolicyTable({
  data,
  handleDelete,
  handleEdit,
}: {
  data: ExcessCarrierPolicy[];
  handleDelete: (policy: ExcessCarrierPolicy) => Promise<void>;
  handleEdit: (policy: ExcessCarrierPolicy) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => data, [data]);

  const columnData: ColumnDef<ExcessCarrierPolicy>[] = [
    {
      header: '',
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center gap1Rem'>
            <Button
              type='button'
              variant='primary'
              size='sm'
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              type='button'
              variant='danger'
              size='sm'
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <FaTrash />
            </Button>
          </div>
        );
      },
    },
    {
      header: 'Carrier Policy Number',
      accessorKey: 'carrierPolicyNo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      sortingFn: 'datetime',
      filterFn: 'equals',
      size: 100,
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Account Number',
      accessorKey: 'accountnumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Premium',
      accessorFn: (d) => `${d.premium}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.premium ?? 0),
    },
    {
      header: 'Treaty Year',
      accessorFn: (d) => `${d.treatyYear ?? 'N/A'}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      size: 100,
    },
    {
      header: 'Default SIR',
      accessorFn: (d) => `${d.defaultSir}`,
      sortingFn: 'text',
      filterFn: 'includesString',
      cell: ({ row }) => accounting.formatMoney(row.original.defaultSir ?? 0),
    },
    {
      header: 'Audited',
      accessorFn: (d) => (d.audited === true ? 'Yes' : 'No'),
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  return (
    <PaginatedTable
      table={table}
      columnResizeMode='onChange'
      showFilters={true}
      selectableRow={true}
    />
  );
}
