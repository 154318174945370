import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { TextLog } from '../ApiTypes/TextLog';
import { TextGridObj } from '../ApiTypes/TextGridObj';
import { TextMessageTemplate } from '../ApiTypes/TextMessageTemplate';

class TextLogApi {
  static getConversationTextList(claimantphonenumber: string) {
    return axios.get<TextLog[]>(
      `${API_URL}/api/textlogs/${claimantphonenumber}`,
      getAuthHeader()
    );
  }
  static addOutgoingLog(request: TextLog) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/textlogs`,
      request,
      getAuthHeader()
    );
  }
  static getLogs() {
    return axios.get<TextGridObj[]>(
      `${API_URL}/api/textlogs/list`,
      getAuthHeader()
    );
  }
  static testSignalRBroadcast() {
    return axios.post<boolean>(
      `${API_URL}/api/textlogs/test`,
      null,
      getAuthHeader()
    );
  }
  static testSendMessage(key: number, number: string) {
    return axios.get<boolean>(
      `${API_URL}/api/textlogs/sendmessage/${key}/${number}`
    );
  }
  static getUnreadLogsCount() {
    return axios.get<number>(
      `${API_URL}/api/textlogs/count/unread`,
      getAuthHeader()
    );
  }
  static getTextMessageTemplateList(userId: string, insurcoid: number) {
    return axios.get<TextMessageTemplate[]>(
      `${API_URL}/api/textlogs/template/user/${userId}/insurco/${insurcoid}`,
      getAuthHeader()
    );
  }
  static getTextMessageTemplateListFull() {
    return axios.get<TextMessageTemplate[]>(
      `${API_URL}/api/textlogs/template`,
      getAuthHeader()
    );
  }
  static getTextMessageTemplateListCustom(userId: string, insurcoid: number) {
    return axios.get<TextMessageTemplate[]>(
      `${API_URL}/api/textlogs/template/custom/user/${userId}/insurco/${insurcoid}`,
      getAuthHeader()
    );
  }
  static addUpdateTextMessageTemplate(request: TextMessageTemplate) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/textlogs/template`,
      request,
      getAuthHeader()
    );
  }
  static deleteTextMessageTemplate(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/textlogs/template/${id}`,
      getAuthHeader()
    );
  }
}

export default TextLogApi;
