import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as signalR from '@microsoft/signalr';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { UserTextConversation } from '../../ApiTypes/UserTextConversation';
import UserApi from '../../Api/UserApi';
import { fetchUserTextConversations } from '../../Actions';

const connection = new signalR.HubConnectionBuilder()
  .withUrl('https://gasclaimsapi.georgia-admin.com/chatHub', {
    // .withUrl('https://localhost:7090/chatHub', {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();

export default function TextingConnection({
  claimantPhoneNumber,
  getConversationTextList,
  getUnreadLogsCount,
}: {
  claimantPhoneNumber: string | null;
  getConversationTextList?: () => void;
  getUnreadLogsCount?: () => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    connection.off('claimant');
    if (claimantPhoneNumber && connection.state === 'Connected') {
      connection.on('claimant', (number, message) => {
        if (claimantPhoneNumber?.replace('-', '') === number) {
          toast.info('New message: ' + message, {
            position: 'bottom-right',
          });
        }
        if (getConversationTextList) getConversationTextList();
      });
    }
  }, [claimantPhoneNumber]);

  useEffect(() => {
    startConnection();
  }, []);

  const startConnection = () => {
    if (connection.state === 'Disconnected') {
      connection
        .start()
        .then(() => {
          connectBroadcast();
        })
        .catch((err) => console.error('Error connecting to hub:', err));
    }
  };

  const connectBroadcast = () => {
    if (connection.state === 'Connected') {
      connection.on(
        'broadcastMessage',
        (
          message,
          username,
          claimantFirstname,
          claimantLastname,
          claimantNumber
        ) => {
          if (
            username.toLowerCase() === userModel?.user?.userId.toLowerCase()
          ) {
            toast.info(message, {
              position: 'bottom-right',
            });
            if (userModel && userModel.user) {
              const request: UserTextConversation = {
                id: 0,
                userId: userModel.user.userId,
                claimNumber: '',
                claimantName:
                  (claimantFirstname ?? '') + ' ' + (claimantLastname ?? ''),
                claimantPhoneNumber: claimantNumber,
                dateCreated: null,
              };
              return UserApi.updateUserTextConversations(request, 'add')
                .then((res) => {
                  if (res.data.success) {
                    dispatch(
                      fetchUserTextConversations(userModel.user!.userId)
                    );
                  } else {
                    toast.error(res.data.message);
                  }
                })
                .catch((err) => {
                  // toast.error('Failed to send text');
                  console.log(err);
                });
            }
            toast.info('open pop up');
          }
          if (getUnreadLogsCount) getUnreadLogsCount();
        }
      );
    }
  };

  const disconnectConversation = () => {
    connection.stop();
  };

  return <></>;
}
