import { createReducer } from '@reduxjs/toolkit';

import { fetchUserTextConversations } from '../Actions/index';
import { UserTextConversation } from '../ApiTypes/UserTextConversation';

type ConversationsReducerType = {
  conversationsList: UserTextConversation[];
};

const initialState: ConversationsReducerType = {
  conversationsList: [],
};

const ConversationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchUserTextConversations.fulfilled, (state, action) => {
    return { ...state, conversationsList: action?.payload };
  });
});

export default ConversationsReducer;
