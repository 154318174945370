import { addDays, addSeconds, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaBalanceScale } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReportApi from '../../Api/ReportApi';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { useAppDispatch, useAppSelector } from '../../Reducers/Store';
import { downloadExcel, parseDatesForServer } from '../../Utils';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import styles from './index.module.css';
import { FileDownload } from '../../Types/FileDownLoad';
import {
  setDocViewFileDownload,
  setShowDocView,
} from '../../Actions/DocViewActions';
import {
  DevExpressReportRequest,
  DxAvailableReports,
} from '../../ApiTypes/DevExpressReportRequest';
import { requestDx } from '../DxReportRequestModal/useDxReportRequestModal';
import { getDxReport } from '../DocViewModal/useDocViewModal';

interface PremiumAndLossSummaryFormValues {
  insurcoid: number;
  treatyYear: number;
  asOf: string;
  action: string;
}

export default function PremiumAndLossModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var lowYear = new Date().getFullYear() - 1970;
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= lowYear; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const setShowDx = (show: boolean) => {
    dispatch(setShowDocView(show));
  };
  const setFileDownload = (data: FileDownload | null) => {
    dispatch(setDocViewFileDownload(data));
  };

  const onSubmit = (values: PremiumAndLossSummaryFormValues) => {
    if (values.action === 'summary') {
      return ReportApi.getPremiumAndLossSummary({
        insurCoId: values.insurcoid,
        startYear: values.treatyYear,
        asOf: values.asOf,
      })
        .then((res) => {
          if (res.data.fileName === 'Failed') {
            toast.error('Failed to get summary');
          } else {
            downloadExcel(res.data.fileName, res.data.file);
            setShow(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed  to get summary');
        });
    } else {
      const request: DevExpressReportRequest = {
        ...requestDx,
        report: DxAvailableReports.rptPremiumAndLoss,
        insurCoId: values.insurcoid,
        asOfDate: values.asOf,
        startYear: values.treatyYear,
      };
      getDxReport(request, setFileDownload, setShowDx);
    }
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='PremiumAndLossModal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='PremiumAndLossModal'>
          <FaBalanceScale className='pe-1' /> Premium and Loss
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              treatyYear: new Date().getFullYear(),
              asOf: format(
                addSeconds(
                  addDays(new Date(), -(new Date().getDate() - 1)),
                  -1
                ),
                'yyyy-MM-dd'
              ),
              action: 'summary',
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='asOf'
                    type='text'
                    label='As of'
                    parse={parseDatesForServer}
                    validate={requiredField}
                    component={FieldBSRenderDate}
                  />
                  <Field
                    name='insurcoid'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='treatyYear'
                    label='Start Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex gap1Rem align-items-center'>
                      <Field
                        name='action'
                        label='Detailed'
                        type='radio'
                        value='detail'
                        checked={values.action === 'detail'}
                        component={FieldBSRenderCheckbox}
                      />
                      <Field
                        name='action'
                        label='Summary'
                        type='radio'
                        value='summary'
                        checked={values.action === 'summary'}
                        component={FieldBSRenderCheckbox}
                      />
                    </div>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Generate'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
