import accounting from 'accounting';
import { format, isBefore } from 'date-fns';
import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaymentsApi from '../../Api/PaymentsApi';
import ProviderApi from '../../Api/ProviderApi';
import { Address } from '../../ApiTypes/Address';
import { Claim } from '../../ApiTypes/Claim';
import { Claimant } from '../../ApiTypes/Claimant';
import { ClaimPaymentTotals } from '../../ApiTypes/ClaimPaymentTotals';
import { ClaimReserveBuckets } from '../../ApiTypes/ClaimReserveBuckets';
import { DocumentStatus } from '../../ApiTypes/DocumentStatus';
import { DuplicateTransactionRequest } from '../../ApiTypes/DuplicateTransactionRequest';
import { PayCodeItem } from '../../ApiTypes/PayCodeItem';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { ScanDoc } from '../../ApiTypes/ScanDoc';
import { Transaction } from '../../ApiTypes/Transaction';
import { useAppSelector } from '../../Reducers/Store';
import {
  displayDateOnly,
  parseDatesForServer,
  setAddressTextString,
} from '../../Utils';
import {
  centsValidation,
  composeValidators,
  requiredField,
} from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import BSRenderText from '../Common/BSRenderText';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../Common/FieldBSRenderDate';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import DocViewModal from '../DocViewModal/DocViewModal';
import { useDocViewModal } from '../DocViewModal/useDocViewModal';
import ProviderLookupModal from '../ProviderLookup/ProviderLookupModal';

import styles from './index.module.css';
import { DocumentUpdateStatusRequest } from '../../ApiTypes/DocumentUpdateStatusRequest';
import { ResponseApiModel } from '../../ApiTypes/ResponseApiModel';

export default function StraightPayForm({
  claim,
  claimant,
  selectedDoc,
  handleSendToCompIq,
  handleHold,
  handleStraightPayDenied,
  handleDuplicate,
  handleAlreadyPaid,
  handleCancelClick,
  claimReserveBuckets,
  claimPaymentTotals,
  getDocuments,
  updateDoc,
}: {
  claim: Claim | null;
  claimant: Claimant | null;
  selectedDoc: ScanDoc | null;
  claimPaymentTotals: ClaimPaymentTotals | null;
  claimReserveBuckets: ClaimReserveBuckets[];
  handleSendToCompIq: () => void;
  handleHold: () => void;
  handleStraightPayDenied: () => void;
  handleDuplicate: () => void;
  handleAlreadyPaid: () => void;
  handleCancelClick: () => void;
  getDocuments: () => void;
  updateDoc: (
    request: DocumentUpdateStatusRequest
  ) => Promise<ResponseApiModel>;
}) {
  const { payCodes } = useAppSelector((state) => state.reference);
  const [claimantAddress, setClaimantAddress] = useState<string>('');
  const [providerAddress, setProviderAddress] = useState<string>('');
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderSearchResult | null>(null);
  const [checkPayableTo, setCheckPayableTo] = useState<string>('');
  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeItem | null>(
    null
  );

  const [fBucketText, setFBucketText] = useState<string>('Reserves');
  const [showProviderLookup, setShowProviderLookup] = useState<boolean>(false);
  const [showCheckDate, setShowCheckDate] = useState<boolean>(false);
  const [showCheckNumber, setShowCheckNumber] = useState<boolean>(false);
  const [submittingNew, setSubmittingNew] = useState<boolean>(false);

  const [
    showAdminAmountGreaterThanReserves,
    setShowAdminAmountGreaterThanReserves,
  ] = useState<JSX.Element | null>(null);
  const [showDuplicateWarning, setShowDuplicateWarning] =
    useState<JSX.Element | null>(null);
  const [showRefundGreaterWarning, setShowRefundGreaterWarning] =
    useState<JSX.Element | null>(null);
  const [showRecoveryIsMoreThanBucket, setShowRecoveryIsMoreThanBucket] =
    useState<JSX.Element | null>(null);
  const [showFinalWarning, setShowFinalWarning] = useState<JSX.Element | null>(
    null
  );

  const {
    showDocViewModal,
    fileDownload,
    setShowDocViewModal,
    setFileDownload,
  } = useDocViewModal();

  let formInstance: FormApi<Transaction, Partial<Transaction>>;

  useEffect(() => {
    handleSelectedDocChange();
  }, [selectedDoc]);

  useEffect(() => {
    handleFBucketText();
  }, [selectedPayCode]);

  const handleSelectedDocChange = () => {
    if (selectedDoc) {
    } else {
      setSelectedProvider(null);
      setCheckPayableTo('');
      setSelectedPayCode(null);
      setShowCheckDate(false);
      setShowCheckNumber(false);
      formInstance.restart();
    }
  };

  const handleFBucketText = () => {
    if (selectedPayCode) {
      let text = 'Reserves';
      let reserveBucket: ClaimReserveBuckets | undefined;
      reserveBucket = claimReserveBuckets.find(
        (b) => b.fBucket === selectedPayCode?.fBucket
      );

      if (reserveBucket) {
        text = `${
          reserveBucket.description
        } - Reserves: ${accounting.formatMoney(reserveBucket.reserves)}`;
      }
      setFBucketText(text);
    } else {
      setFBucketText('Reserves');
    }
  };

  const stopAllSubmitting = () => {
    setSubmittingNew(false);
  };

  const onSubmit = async (values: Transaction) => {
    if (selectedDoc?.statusId === DocumentStatus.StraightPayApproved) {
      return handleCancelClick();
    }
    if (!claim || !claimant) {
      toast.error('Document must have an assigned claim and claimant');
      return;
    }
    if (values.payee?.toUpperCase() === 'P' && !selectedProvider) {
      toast.error('Must specify a provider');
      return;
    }
    if (selectedPayCode?.txType === 'PAYMENT') {
      if (!values.serviceFrom || !values.serviceTo) {
        toast.error('Service From and Service TO Dates are required ');
        return;
      }
      if (isBefore(new Date(values.serviceTo), new Date(values.serviceFrom))) {
        toast.error('Service From Date must be less than Service To date.');
        return;
      }
      if (
        isBefore(new Date(values.serviceFrom), new Date(claim.injuryDate ?? ''))
      ) {
        toast.error(
          'Service From date must be greater or equal to injury date.'
        );
        return;
      }
    } else {
      if (!values.checkNumber) {
        toast.error('Please enter a check number');
        return;
      }
      if (!values.checkDate) {
        toast.error('Please enter a check date');
        return;
      }
    }

    // submitting
    setSubmittingNew(true);
    if (selectedPayCode?.txType === 'PAYMENT') {
      let taxId: string = '';
      if (values.payee === 'P') {
        taxId = selectedProvider?.taxId ?? '';
      } else {
        taxId = claimant?.ssn ?? '';
      }

      let request: DuplicateTransactionRequest = {
        amount: values.amount ?? 0,
        claimNumber: values.claimNo ?? '',
        fromDate: values.serviceFrom!,
        taxId: taxId,
        toDate: values.serviceTo!,
      };

      const dupResponse = await PaymentsApi.checkForDuplicatePayments(
        request
      ).catch((err) => {
        console.log(err);
      });
      if (dupResponse && dupResponse.data.duplicateExists) {
        let savedResolve:
          | ((value: boolean | PromiseLike<boolean>) => void)
          | null = null;
        const valueReturned = new Promise<boolean>((resolve, reject) => {
          savedResolve = resolve;
        });
        const { enteredBy, txEnterDate } = dupResponse.data;

        const alert = (
          <Alert
            variant='danger'
            className='mt-3'
            dismissible
            onClose={() => {
              savedResolve && savedResolve(false);
              setShowDuplicateWarning(null);
            }}
          >
            <p>{`Possible duplicate with transaction`}</p>
            <p>{`entered on ${displayDateOnly(
              txEnterDate ?? ''
            )} by ${enteredBy}`}</p>
            <p>Would you like to Proceed?</p>
            <div className='d-flex justify-content-around'>
              <Button
                type='button'
                onClick={() => {
                  savedResolve && savedResolve(true);
                  setShowDuplicateWarning(null);
                }}
                variant='danger'
              >
                Yes
              </Button>
            </div>
          </Alert>
        );
        setShowDuplicateWarning(alert);

        const res = await valueReturned;
        if (!res) {
          stopAllSubmitting();
          return;
        }
      }
    }
    const txAmount = values.amount ?? 0;
    const totalPaid =
      (claimPaymentTotals?.expense ?? 0) +
      (claimPaymentTotals?.medical ?? 0) +
      (claimPaymentTotals?.indemnity ?? 0) +
      (claimPaymentTotals?.legal ?? 0);
    const { medical, indemnity, expense, legal } = claimPaymentTotals!;

    if (txAmount < 0) {
      if (totalPaid + txAmount < 0) {
        stopAllSubmitting();
        const alert = (
          <Alert
            variant='danger'
            className='mt-3'
            dismissible
            onClose={() => setShowRefundGreaterWarning(null)}
          >
            <p>
              Amount of refund/reimbursement is greater than the amount of
              payments.
            </p>
            <p>{`Total Paid: ${accounting.formatMoney(totalPaid)}`}</p>
            <p>{`Medical Paid: ${accounting.formatMoney(medical)}`}</p>
            <p>{`Indemnity Paid: ${accounting.formatMoney(indemnity)}`}</p>
            <p>{`Expense Paid: ${accounting.formatMoney(expense)}`}</p>
            <p>{`Legal Paid: ${accounting.formatMoney(legal)}`}</p>
          </Alert>
        );
        setShowRefundGreaterWarning(alert);
        stopAllSubmitting();
        return;
      } else {
        let amountok = true;
        switch (selectedPayCode?.fBucket) {
          case '1':
            if (claimPaymentTotals?.medical ?? 0 + txAmount < 0)
              amountok = false;
            break;
          case '2':
            if (claimPaymentTotals?.indemnity ?? 0 + txAmount < 0)
              amountok = false;
            break;
          case '3':
            if (claimPaymentTotals?.expense ?? 0 + txAmount < 0)
              amountok = false;
            break;
          case '4':
            if (claimPaymentTotals?.legal ?? 0 + txAmount < 0) amountok = false;
            break;
        }

        if (!amountok) {
          const alert = (
            <Alert
              variant='danger'
              className='mt-3'
              dismissible
              onClose={() => setShowRecoveryIsMoreThanBucket(null)}
            >
              <p>
                The amount of the recovery is more than the amount of payments
                for the financial bucket,
              </p>
              <p>
                however the total amount may be able to be split between other
                buckets.
              </p>
              <p>{`Medical Paid: ${accounting.formatMoney(medical)}`}</p>
              <p>{`Indemnity Paid: ${accounting.formatMoney(indemnity)}`}</p>
              <p>{`Expense Paid: ${accounting.formatMoney(expense)}`}</p>
              <p>{`Legal Paid: ${accounting.formatMoney(legal)}`}</p>
            </Alert>
          );
          setShowRecoveryIsMoreThanBucket(alert);
          stopAllSubmitting();
          return;
        }
      }
    }

    const reserveBucket = claimReserveBuckets.find(
      (b) => b.fBucket === selectedPayCode?.fBucket
    );
    const reserveAmt = reserveBucket?.reserves ?? 0;
    if (txAmount > reserveAmt && selectedPayCode?.txType === 'PAYMENT') {
      let savedResolve:
        | ((value: boolean | PromiseLike<boolean>) => void)
        | null = null;
      const valueReturned = new Promise<boolean>((resolve, reject) => {
        savedResolve = resolve;
      });

      const alert = (
        <Alert
          variant='danger'
          className='mt-3'
          dismissible
          onClose={() => {
            savedResolve && savedResolve(false);
            setShowAdminAmountGreaterThanReserves(null);
          }}
        >
          <p>{`The amount of the transaction ${accounting.formatMoney(
            txAmount
          )} is greater than the reserve amount ${accounting.formatMoney(
            reserveAmt
          )}`}</p>
          <p>Would you like to Proceed?</p>
          <div className='d-flex justify-content-around'>
            <Button
              type='button'
              onClick={() => {
                savedResolve && savedResolve(true);
                setShowAdminAmountGreaterThanReserves(null);
              }}
              variant='danger'
            >
              Yes
            </Button>
          </div>
        </Alert>
      );

      setShowAdminAmountGreaterThanReserves(alert);

      const res = await valueReturned;
      if (!res) {
        stopAllSubmitting();
        return;
      }
    }

    // final warning
    let savedResolve: ((value: boolean | PromiseLike<boolean>) => void) | null =
      null;
    const valueReturned = new Promise<boolean>((resolve, reject) => {
      savedResolve = resolve;
    });

    const alert = (
      <Alert
        variant='danger'
        className='mt-3'
        dismissible
        onClose={() => {
          savedResolve && savedResolve(false);
          setShowFinalWarning(null);
        }}
      >
        <p>{`You are about to create a request for payment of ${accounting.formatMoney(
          txAmount
        )} to ${
          values.payee === 'C'
            ? selectedDoc?.claimantName
            : selectedProvider?.companyName ?? ''
        }`}</p>
        <p>Are you sure you want to continue?</p>
        <div className='d-flex justify-content-around'>
          <Button
            type='button'
            onClick={() => {
              savedResolve && savedResolve(true);
              setShowFinalWarning(null);
            }}
            variant='danger'
          >
            Yes
          </Button>
        </div>
      </Alert>
    );

    setShowFinalWarning(alert);

    const res = await valueReturned;
    if (!res) {
      stopAllSubmitting();
      return;
    }

    // submit

    const today = parseDatesForServer(format(new Date(), 'yyyy-MM-dd'));

    if (values.checkNumber) {
      if (new Date(values.checkDate ?? '') < new Date()) {
        values.checkDate = today;
      }
    }
    if (!values.serviceTo) {
      values.serviceTo = today;
    }
    if (!values.serviceFrom) {
      values.serviceFrom = today;
    }

    const transaction: Transaction = {
      txId: 0,
      claimNo: values.claimNo,
      txDate: today,
      txEnterDate: today,
      payee: values.payee,
      providerId:
        values.payee === 'P'
          ? selectedProvider?.providerId ?? null
          : claimant.claimantId ?? null,
      serviceFrom: values.serviceFrom ?? null,
      serviceTo: values.serviceTo ?? null,
      payCode: values.payCode,
      checkId: null,
      amount: values.amount,
      checkNumber:
        selectedPayCode?.txType !== 'PAYMENT'
          ? values.checkNumber ?? null
          : null,
      checkDate:
        selectedPayCode?.txType !== 'PAYMENT' ? values.checkDate ?? null : null,
      memo: values.memo,
      fbucket: selectedPayCode?.fBucket ?? null,
      paymentNumber: null,
      status: 'N',
      bankId: null,
      pmtApproved: null,
      pmtApprovedBy: null,
      pmtBatchId: null,
      source: null,
      importId: null,
      documentControlNumber: null,
      enteredBy: null,
      alteredBy: null,
      docId: selectedDoc?.docId ?? null,
      approvalDate: null,
      managerApproved: null,
      managerApprovedBy: null,
      managerApprovedDate: null,
      requireSecondaryApproval: null,
      claimSubrogationId: values.claimSubrogationId,
    };

    saveTransaction(transaction);
  };

  const saveTransaction = (transaction: Transaction) => {
    return PaymentsApi.SavePayment(transaction)
      .then((res) => {
        if (res.data.success) {
          const data: DocumentUpdateStatusRequest = {
            docId: transaction.docId!,
            docStatusId: DocumentStatus.StraightPayApproved,
            claimNumber: transaction.claimNo ?? '',
            note: 'Marked Approved By Straight Pay.',
          };
          updateStraightPayRecent(transaction.providerId ?? 0);
          stopAllSubmitting();
          return handleDocUpdate(data);
        } else {
          toast.error(res.data.message ?? 'Failed to submit straight pay');
          stopAllSubmitting();
        }
      })
      .catch((err) => {
        console.log(err);
        stopAllSubmitting();
        toast.error('Failed to submit straight pay');
      });
  };

  const handleDocUpdate = (request: DocumentUpdateStatusRequest) => {
    updateDoc(request)
      .then((data) => {
        if (data.success) {
          formInstance.reset();
          handleCancelClick();
        }
      })
      .catch((err) => console.log(err));
  };

  const updateStraightPayRecent = (providerId: number) => {
    ProviderApi.updateRecentStraightPay(providerId)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePayeeChange = (value: string) => {
    if (value === 'C') {
      getClaimantAddress();
      setCheckPayableTo(`${claimant?.firstName} ${claimant?.lastName}`);
    }
    if (value === 'P') {
      getProviderAddress();
      let name: string = '';
      if (selectedProvider) {
        name = selectedProvider.companyName;
      }
      setCheckPayableTo(name);
    }
  };
  const handleSelectedProvider = (provider: ProviderSearchResult) => {
    setSelectedProvider(provider);
    getProviderAddress(provider);
    setCheckPayableTo(provider.companyName);
    setShowProviderLookup(false);
  };

  const getProviderAddress = (provider?: ProviderSearchResult) => {
    let address: Address | null = null;
    if (provider) {
      const { address1, address2, city, state, zip } = provider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
    } else if (selectedProvider) {
      const { address1, address2, city, state, zip } = selectedProvider;
      address = {
        address1,
        address2,
        city,
        state,
        zip,
        addressId: 0,
        addressName: '',
        ownerId: 0,
        ownerTable: '',
      };
    }
    setAddressTextString(address, setProviderAddress);
  };

  const getClaimantAddress = () => {
    let address: Address | null = null;
    if (claimant && claimant.usePaymentAddress) {
      address = claimant?.paymentAddress ?? null;
    } else {
      address = claimant?.mainAddress ?? null;
    }
    setAddressTextString(address, setClaimantAddress);
    // console.log(address);
  };

  const handleTaxIdFocus = () => {
    setShowProviderLookup(true);
  };

  const checkTxType = (payCode?: string) => {
    let code: PayCodeItem | undefined;
    if (payCode) {
      code = payCodes.find((p) => p.payCode === payCode);
      setSelectedPayCode(code ?? null);
    }

    if (code?.txType === 'RECOVERY' || code?.txType === 'REIMBURSEMENT') {
      setShowCheckDate(true);
      setShowCheckNumber(true);
    } else {
      setShowCheckDate(false);
      setShowCheckNumber(false);
    }
  };
  const handlePayCodeChange = (payCode?: string) => {
    checkTxType(payCode);
  };

  return (
    <div
    // {...{
    //   style: {
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 10,
    //     right: 55,
    //   },
    // }}
    >
      <RFForm
        onSubmit={onSubmit}
        initialValues={{
          claimNo: selectedDoc?.claimno,
          payee: 'P',
          serviceFrom: selectedDoc?.serviceFrom,
          serviceTo: selectedDoc?.serviceTo,
        }}
        validate={(values) => {
          const errors: {
            [Property in keyof Transaction]?: string;
          } = {};

          if (!values?.payee) {
            errors.payee = 'Required';
          }
          return errors;
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          const payeeFieldState = form.getFieldState(
            'payee' as keyof Transaction
          )!;
          return (
            <Form onSubmit={handleSubmit}>
              <div className={styles.firstRow}>
                <fieldset className={` h-100 py-0`} disabled={!selectedDoc}>
                  <div
                    style={
                      payeeFieldState?.submitFailed && payeeFieldState?.invalid
                        ? {
                            border: '1px solid red',
                            borderRadius: '.25rem',
                          }
                        : {}
                    }
                  >
                    <legend className='col-form-label col-sm-2 py-0'>
                      Payee
                    </legend>
                    <Field
                      name='payee'
                      type='radio'
                      label='Claimant'
                      value='C'
                      checked={values?.payee?.toUpperCase() === 'C'}
                      onCheckChange={handlePayeeChange}
                      component={FieldBSRenderCheckbox}
                    />
                    <Field
                      name='payee'
                      type='radio'
                      label='Provider'
                      value='P'
                      checked={values?.payee?.toUpperCase() === 'P'}
                      onCheckChange={handlePayeeChange}
                      component={FieldBSRenderCheckbox}
                    />
                  </div>
                </fieldset>
                <div>
                  <Field
                    name='claimNo'
                    label='Claim Number'
                    type='text'
                    validate={requiredField}
                    component={FieldBSRenderText}
                  />
                  <BSRenderText
                    name='claimantName'
                    label='Claimant'
                    type='text'
                    readOnly
                    value={
                      claimant
                        ? `${claimant.firstName} ${claimant.lastName}`
                        : ''
                    }
                  />
                  <div className='d-flex align-items-center gap1Rem'>
                    {showCheckNumber && (
                      <Field
                        name='checkNumber'
                        label='Check Number'
                        type='text'
                        component={FieldBSRenderText}
                      />
                    )}
                    {showCheckDate && (
                      <Field
                        name='checkDate'
                        label='Check Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className='d-flex align-items-center gap1Rem'>
                    <BSRenderText
                      name='taxId'
                      label='Tax Id Lookup'
                      type='text'
                      value={selectedProvider?.taxId ?? ''}
                      onClick={() => {
                        if (values?.payee?.toUpperCase() === 'P') {
                          handleTaxIdFocus();
                        }
                      }}
                    />
                    <Field
                      name='payCode'
                      label='Pay Code'
                      options={payCodes}
                      onChange={handlePayCodeChange}
                      optionMethod={(options: PayCodeItem[]) =>
                        options.map((o) => (
                          <option key={o.payCode} value={o.payCode}>
                            {o.description}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                  </div>
                  <BSRenderText
                    name='checkPayableTo'
                    label='Payee'
                    type='text'
                    value={checkPayableTo}
                  />
                  <div
                    className={`${styles.height20px} d-flex align-items-center`}
                  >
                    <p>{fBucketText}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.secondRow}`}>
                <div className={styles.height20px}>
                  <div className={`position-relative mb-3`}>
                    <label
                      htmlFor='mailingAddress'
                      className='form-label fs-6  m-0'
                    >
                      Mailing Address
                    </label>
                    <div>
                      <textarea
                        name='mailingAddress'
                        id='mailingAddress'
                        rows={2}
                        value={
                          values?.payee?.toUpperCase() === 'C'
                            ? claimantAddress
                            : values?.payee?.toUpperCase() === 'P'
                            ? providerAddress
                            : ''
                        }
                        className='form-control form-control-sm'
                        placeholder='Mailing Address'
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <Field
                  name='memo'
                  label='Memo'
                  rows={2}
                  component={FieldBSRenderTextArea}
                />
              </div>
              <div className={`${styles.thirdRow}`}>
                <Field
                  name='amount'
                  label='Amount'
                  type='text'
                  format={formatNumbers}
                  parse={cleanMoney}
                  validate={composeValidators(centsValidation, requiredField)}
                  component={FieldBSRenderMoney}
                />
                <Field
                  name='serviceFrom'
                  label='Service From'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
                <Field
                  name='serviceTo'
                  label='Service To'
                  parse={parseDatesForServer}
                  component={FieldBSRenderDate}
                />
              </div>
              {showAdminAmountGreaterThanReserves !== null &&
                showAdminAmountGreaterThanReserves}
              {showDuplicateWarning !== null && showDuplicateWarning}
              {showRefundGreaterWarning !== null && showRefundGreaterWarning}
              {showRecoveryIsMoreThanBucket !== null &&
                showRecoveryIsMoreThanBucket}
              {showFinalWarning !== null && showFinalWarning}
              <div className='d-flex justify-content-between align-items-center gap1Rem mt-3'>
                <Link
                  to={`/scandocs/${selectedDoc?.docId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button
                    type='button'
                    variant='outline-primary'
                    size='sm'
                    onClick={() => {
                      // handleViewDoc();
                    }}
                  >
                    View Document
                  </Button>
                </Link>

                <Link to={selectedDoc ? `/claims/${selectedDoc?.claimno}` : ''}>
                  <Button type='button' variant='outline-primary' size='sm'>
                    Open Claim
                  </Button>
                </Link>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    handleSendToCompIq();
                  }}
                >
                  Send to CompIQ
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    handleHold();
                  }}
                >
                  Hold for more Info
                </Button>
                <Button
                  type='button'
                  variant='outline-danger'
                  size='sm'
                  onClick={() => {
                    handleStraightPayDenied();
                  }}
                >
                  Straight Pay Denied
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    handleDuplicate();
                  }}
                >
                  Straight Pay Duplicate
                </Button>
                <Button
                  type='button'
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    handleAlreadyPaid();
                  }}
                >
                  Already Paid (Complete)
                </Button>
                <Button
                  type='button'
                  variant='outline-danger'
                  size='sm'
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel Payment
                </Button>
                <Button type='submit' variant='outline-success' size='sm'>
                  {submittingNew ? (
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    'Complete and Pay'
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />

      <ProviderLookupModal
        selectProvider={handleSelectedProvider}
        selectedPayment={null}
        disabled={false}
        show={showProviderLookup}
        setShow={setShowProviderLookup}
      />
      <DocViewModal
        show={showDocViewModal}
        setShow={setShowDocViewModal}
        base64Data={fileDownload?.file ?? ''}
        fileName={fileDownload?.fileName ?? ''}
        setFileDownload={setFileDownload}
      />
    </div>
  );
}
