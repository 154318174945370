import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { EdiProgressReport } from '../ApiTypes/EdiProgressReport';
import { EdiTransaction } from '../ApiTypes/EdiTransaction';
import { FullClaim } from '../ApiTypes/FullClaim';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { FileDownload } from '../Types/FileDownLoad';

class EdiTransactionApi {
  static getEdiTransactionsForClaim(claimNumber: string) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/claims/${claimNumber}/editransactions`,
      getAuthHeader()
    );
  }
  static getEdiTransactionById(claimNumber: string, id: number) {
    return axios.get<EdiTransaction>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/${id}`,
      getAuthHeader()
    );
  }
  static createEdiTransaction(
    claimNumber: string,
    transaction: EdiTransaction
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/editransactions`,
      transaction,
      getAuthHeader()
    );
  }
  static updateEdiTransaction(
    claimNumber: string,
    transaction: EdiTransaction
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/${transaction.id}`,
      transaction,
      getAuthHeader()
    );
  }
  static deleteEdiTransaction(claimNumber: string, transactionId: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/${transactionId}`,
      getAuthHeader()
    );
  }
  static sendEdiTransaction(claimNumber: string, transactionId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/${transactionId}/send`,
      {},
      getAuthHeader()
    );
  }
  static markTransAck(claimNumber: string, transactionId: number) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/${transactionId}/marktransack`,
      {},
      getAuthHeader()
    );
  }
  static getEdiProgressReport(claimNumber: string, wc4id: number) {
    return axios.get<EdiProgressReport>(
      `${API_URL}/api/claims/${claimNumber}/progressreport/${wc4id}`,
      getAuthHeader()
    );
  }
  static updateEdiProgressReport(
    claimNumber: string,
    report: EdiProgressReport
  ) {
    return axios.put<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/progressreport/${report.wc4id}`,
      report,
      getAuthHeader()
    );
  }
  static saveEdiProgressReportToDocuments(claimNumber: string, wc4id: number) {
    return axios.get<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/progressreport/${wc4id}/save`,
      getAuthHeader()
    );
  }
  static createEdiProgressReport(
    claimNumber: string,
    report: EdiProgressReport
  ) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/claims/${claimNumber}/progressreport`,
      report,
      getAuthHeader()
    );
  }
  static getByStatus(status: number, start: string, end: string) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/editransactions/searchbystatus?status=${status}&start=${start}&end=${end}`,
      getAuthHeader()
    );
  }
  static getFROIsNeedingSROIs(start: string, end: string) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/editransactions/searchbyneedsroi?start=${start}&end=${end}`,
      getAuthHeader()
    );
  }
  static getFROInoSROIMOClosed(start: string, end: string) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/editransactions/searchbynosroimoclosed?start=${start}&end=${end}`,
      getAuthHeader()
    );
  }
  static getPending(start: string, end: string) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/editransactions/pending?start=${start}&end=${end}`,
      getAuthHeader()
    );
  }
  static getEdiFiles(formType: string, maintCode: string, claimNo: string) {
    return axios.get<{ [key: string]: boolean }[]>(
      `${API_URL}/api/edifiles?formType=${formType}&maintCode=${maintCode}&claimNo=${claimNo}`,
      getAuthHeader()
    );
  }
  static getLostTimeNoFroi(treatyYear: number) {
    return axios.get<FullClaim[]>(
      `${API_URL}/api/claims/needfroi?treatyYear=${treatyYear}`,
      getAuthHeader()
    );
  }
  static getSubmittedStatus() {
    return axios.get<FileDownload>(
      `${API_URL}/api/editransactions/submittedstatus`,
      getAuthHeader()
    );
  }
  static getEdiLogsByDateRange(
    from: string,
    to: string,
    errorsOnly: boolean,
    insCoId: number
  ) {
    return axios.get<EdiTransaction[]>(
      `${API_URL}/api/edilogs?from=${from}&to=${to}&errorsOnly=${errorsOnly}&insCoId=${insCoId}`,
      getAuthHeader()
    );
  }
  static removeSBWCDuplicates() {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/editransactions/removedupes`,
      {},
      getAuthHeader()
    );
  }
  static getEdiProgressReportData(claimNumber: string) {
    return axios.get<EdiProgressReport>(
      `${API_URL}/api/claims/${claimNumber}/editransactions/ediprogressreport-data`,
      getAuthHeader()
    );
  }
}

export default EdiTransactionApi;
