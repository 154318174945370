import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { useParams } from 'react-router-dom';
import RatingApi from '../../../Api/RatingApi';
import { Rating } from '../../../ApiTypes/Rating';
import { displayDateOnly } from '../../../Utils';
import { Button } from 'react-bootstrap';
import { useAppSelector } from '../../../Reducers/Store';
import { toast } from 'react-toastify';

export default function ClaimReviewExternalAudits() {
  const { claimNumber } = useParams();
  const [ratings, setRatings] = useState<Rating[]>([]);
  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getRatingsForCompany();
  }, [claimNumber]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableData = useMemo(() => ratings, [ratings]);

  const handleSubmit = () => {
    return RatingApi.handleRatingSubmitted(claimNumber ?? '')
      .then((res) => {
        toast.success('Success');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnData: ColumnDef<Rating>[] = [
    {
      header: '',
      id: 'submit',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      cell: ({ row }) => {
        return (
          <div className='d-flex justify-content-center align-items-center'>
            <a
              href={`https://worksheets.georgia-admin.com/public/blank/submit.aspx?key=${row.original.id}&ClaimNumber=${claimNumber}&EmailAddress=${userModel?.user?.emailAddress}&Submitter=${userModel?.user?.userName}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                type='button'
                variant='primary'
                size='sm'
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </a>
          </div>
        );
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Date Created',
      accessorKey: 'dateEntered',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
    },
    {
      header: 'Created By',
      accessorFn: (d) => `${d.user?.firstName} ${d.user?.lastName}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },

    {
      header: 'Comments',
      accessorKey: 'additionalComments',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      size: 200,
    },
  ];
  const columns = useMemo(() => columnData, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    paginateExpandedRows: false,
  });

  const getRatingsForCompany = () => {
    RatingApi.getRatingsForCompany(1)
      .then((res) => {
        setRatings(
          res.data.filter(
            (x) => !x.name?.toLocaleLowerCase().includes('daniel')
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        selectableRow={true}
        highlightRow={true}
      />
    </>
  );
}
