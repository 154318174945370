import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextLogApi from '../../Api/TextLogApi';
import { TextLog } from '../../ApiTypes/TextLog';
import { Field, Form as RFFForm } from 'react-final-form';
import { displayDateAndTimeLocal } from '../../Utils';
import styles from './index.module.css';
import { Claimant } from '../../ApiTypes/Claimant';
import { Button, Form, Spinner } from 'react-bootstrap';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';
import { FormApi } from 'final-form';
import ClaimantApi from '../../Api/ClaimantApi';
import { FaClipboardList } from 'react-icons/fa';
import TextTemplatesModal from './TextTemplatesModal';
import TextTemplateManageModal from './TextTemplateManageModal';
import { useAppSelector } from '../../Reducers/Store';
import DocumentsApi from '../../Api/DocumentsApi';
import TextingConnection from './TextingConnection';

export default function TextConversationPage() {
  const { userModel } = useAppSelector((state) => state.user);
  let { claimantId, insurcoid, scandocId } = useParams();
  const [claimant, setclaimant] = useState<Claimant | null>(null);
  const [showTemplates, setshowTemplates] = useState<boolean>(false);
  const [showManageTemplates, setshowManageTemplates] =
    useState<boolean>(false);

  const [data, setData] = useState<TextLog[]>([]);
  let formInstance: FormApi<TextLog>;

  const getConversationTextList = () => {
    if (claimant && claimant.cellPhone) {
      TextLogApi.getConversationTextList(claimant?.cellPhone?.replace('-', ''))
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData([]);
    }
  };

  const getClaimant = (claimantId: number) => {
    ClaimantApi.getClaimantById(claimantId)
      .then((res) => {
        setclaimant(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get claimant');
      });
  };

  useEffect(() => {
    if (claimantId) {
      getClaimant(Number(claimantId));
    }
  }, [claimantId]);

  useEffect(() => {
    if (claimant) {
      getConversationTextList();
      if (scandocId) {
        getSecureShareDirectLink(Number(scandocId));
      }
    }
  }, [claimant]);

  const getSecureShareDirectLink = async (scandocId: number) => {
    if (scandocId > 0) {
      await DocumentsApi.getSecureShareDirectLink(scandocId)
        .then((res) => {
          if (res.data === 'Error') {
            toast.error('Error retrieving document');
          } else {
            setTextMessageTemplate(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to get document');
        });
    }
  };

  const onSubmit = (values: TextLog) => {
    return TextLogApi.addOutgoingLog(values)
      .then((res) => {
        if (res.data.success) {
          formInstance.reset();
          getConversationTextList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Failed to send text');
      });
  };

  const setTextMessageTemplate = (textBody: string) => {
    formInstance.change('body', textBody);
    console.log(textBody);
  };

  return (
    <div className={`px-2`}>
      <div className={`${styles.textTopInfo}`}>
        <div>From Phone #: (770) 609-4545</div>
        <div>
          Claimant Name: {claimant?.firstName} {claimant?.lastName}
        </div>
        <div>Claimant Phone #: {claimant?.cellPhone}</div>
      </div>
      <div
        className={`d-flex flex-column-reverse border w-100 overflow-auto ${styles.textBackground}`}
        style={{ height: '345px' }}
      >
        <div className='d-flex flex-column'>
          {data.map((text) => {
            return text.direction === 'incoming-api' ? (
              <div key={text.id} className='d-flex flex-column'>
                <div className={`${styles.fromMessage}`}>
                  <div className={`${styles.fromMessageBody}`}>{text.body}</div>
                </div>
                <div
                  key={'date-' + text.id}
                  className={`${styles.fromMessage}`}
                >
                  <div className={`${styles.messageDate}`}>
                    {displayDateAndTimeLocal(text.dateCreated ?? '')}
                  </div>
                </div>
              </div>
            ) : (
              <div key={text.id} className='d-flex flex-column'>
                <div key={text.id} className={`${styles.toMessage}`}>
                  <div className={`${styles.toMessageBody}`}>{text.body}</div>
                </div>
                <div key={'date-' + text.id} className={`${styles.toMessage}`}>
                  <div className={`${styles.messageDate}`}>
                    {displayDateAndTimeLocal(text.dateCreated ?? '')}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <RFFForm
        onSubmit={onSubmit}
        initialValues={{
          numberTo: claimant?.cellPhone,
          firstName: claimant?.firstName,
          lastName: claimant?.lastName,
          claimantPhoneNumber: claimant?.cellPhone,
          body: '',
        }}
        render={({ handleSubmit, form, values, submitting }) => {
          formInstance = form;
          return (
            <Form onSubmit={handleSubmit} className={`${styles.formSubmit}`}>
              <div className={`d-flex`}>
                <div className={`w-100`}>
                  <Field
                    name='body'
                    label='Message'
                    rows={2}
                    component={FieldBSRenderTextArea}
                  />
                </div>
                <div className={styles.claimantFormButtonDiv}>
                  <Button type='submit' size='sm' variant='primary'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Send'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
      <div className='d-flex align-items-center gap-2'>
        {data.length > 0 && (
          <div
            className={`${styles.viewTemplateButton}`}
            onClick={() => setshowTemplates(true)}
          >
            <FaClipboardList />
            View Templates
          </div>
        )}
        <div
          className={`${styles.viewTemplateButton}`}
          onClick={() => setshowManageTemplates(true)}
        >
          Manage Templates
        </div>
      </div>
      <TextTemplatesModal
        show={showTemplates}
        setShow={setshowTemplates}
        userId={userModel?.user?.userId!}
        insurcoId={insurcoid ? Number(insurcoid) : 0}
        setTextMessageTemplate={setTextMessageTemplate}
      />
      <TextTemplateManageModal
        show={showManageTemplates}
        setShow={setshowManageTemplates}
      />
      <TextingConnection
        claimantPhoneNumber={claimant?.cellPhone?.replace('-', '') ?? null}
        getConversationTextList={getConversationTextList}
      />
    </div>
  );
}
