import React, { useState, useEffect } from 'react';
import { FaColumns, FaPlusCircle } from 'react-icons/fa';
import { useAppSelector } from '../../Reducers/Store';
import { Form as RFForm, Field } from 'react-final-form';
import PageScaffold from '../PageScaffold/PageScaffold';
import styles from './index.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import { InsuranceCompany } from '../../ApiTypes/InsuranceCompany';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderCheckbox from '../Common/FieldBSRenderCheckbox';
import { WcPanel } from '../../ApiTypes/WcPanel';
import WcPanelApi from '../../Api/WcPanelApi';
import { toast } from 'react-toastify';
import WCPanelTable from './WCPanelTable';
import { FormApi } from 'final-form';
import { Link } from 'react-router-dom';

export default function WCPanels() {
  const { insuranceCompaniesForUser } = useAppSelector(
    (state) => state.reference
  );

  const [years, setYears] = useState<number[]>([]);
  const [panels, setPanels] = useState<WcPanel[]>([]);

  let formInstance: FormApi<
    {
      insurCoId: number;
      treatyYear: number;
      searchByYear: boolean;
    },
    Partial<{
      insurCoId: number;
      treatyYear: number;
      searchByYear: boolean;
    }>
  >;

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var lowYear = new Date().getFullYear() - 1970;
    var thisYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= lowYear; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
  };

  const onSearch = (values: {
    insurCoId: number;
    treatyYear: number;
    searchByYear: boolean;
  }) => {
    return WcPanelApi.searchWcPanels(
      values.treatyYear,
      values.searchByYear,
      values.insurCoId ?? 0
    )
      .then((res) => {
        setPanels(res.data);
      })
      .catch((err) => {
        console.log(err);
        setPanels([]);
        toast.error('Failed to search for WC Panels');
      });
  };

  const handleDelete = (panel: WcPanel) => {
    WcPanelApi.deletePanelItem(panel.id)
      .then((res) => {
        if (res.data.success) {
          toast.success('Deleted');
          formInstance.submit();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to panel item');
      });
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light`}>
        <div className='ps-3 d-flex'>
          <FaColumns className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>Work Comp Panels</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-around align-items-center'>
        <RFForm
          onSubmit={onSearch}
          initialValues={{
            treatyYear: new Date().getFullYear(),
            searchByYear: true,
          }}
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-center align-items-center gap1Rem'>
                  <Field
                    name='insurCoId'
                    label='Account'
                    options={insuranceCompaniesForUser}
                    optionMethod={(options: InsuranceCompany[]) =>
                      options.map((o) => (
                        <option key={o.insurcoid} value={o.insurcoid}>
                          {`${o.accountnumber} - ${o.shortname}`}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='treatyYear'
                    label='Treaty Year'
                    options={years}
                    optionMethod={(options: number[]) =>
                      options.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))
                    }
                    validate={requiredField}
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='searchByYear'
                    type='checkbox'
                    label='Search By Year Only'
                    checked={values.searchByYear === true}
                    component={FieldBSRenderCheckbox}
                  />
                  <Button type='submit' variant='primary' size='sm'>
                    {submitting ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      'Search'
                    )}
                  </Button>
                  <Button
                    type='button'
                    variant='secondary'
                    size='sm'
                    onClick={() => {
                      form.reset();
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </Form>
            );
          }}
        />
        <Link to='/wcpanels/new'>
          <Button
            type='button'
            className='button-icon-text'
            variant='outline-primary'
            size='sm'
          >
            <FaPlusCircle /> New
          </Button>
        </Link>
      </div>
      <WCPanelTable data={panels} handleDelete={handleDelete} />
    </PageScaffold>
  );
}
