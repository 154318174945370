import React from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import { FaImage } from 'react-icons/fa';
import { LetterLogoOptions } from '../../../ApiTypes/LetterLogoOptions';
import { useAppSelector } from '../../../Reducers/Store';

import styles from './index.module.css';

export default function LetterOptions({
  show,
  setShow,
  handleSelection,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSelection: (value: number) => void;
}) {
  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      aria-labelledby='Letter-Options-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Letter-Options-Form-modal'
        >
          <FaImage className='pe-1' /> Letter Options
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <h4>What type of letter would you like?</h4>
          <div className={`${styles.letterOptionButtons}`}>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleSelection(LetterLogoOptions.GASLogo)}
            >
              GAS Logo Header
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleSelection(LetterLogoOptions.NoLogo)}
            >
              Empty Logo Header
            </Button>
            <Button
              type='button'
              variant='outline-primary'
              size='lg'
              onClick={() => handleSelection(LetterLogoOptions.AccountLogo)}
              disabled={
                claim?.insurcoid !== 4 &&
                claim?.insurcoid !== 7 &&
                claim?.insurcoid !== 6
              }
            >
              Account Header
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
