import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  FilterFn,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Button, Form } from 'react-bootstrap';
import { FaBan, FaEdit, FaPlusCircle, FaRegStopCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { VPaymentTab } from '../../../ApiTypes/VPaymentTab';
import { displayDateOnly } from '../../../Utils';
import accounting from 'accounting';
import { useAppSelector } from '../../../Reducers/Store';
import { SiteFunctionsEnum } from '../../../ApiTypes/SiteFunctions';

export default function PaymentsTable({
  payments,
  getPayments,
  show,
  setShow,
  setSelectedPayment,
  selectedPayment,
  transferPayment,
  shouldVoidPayment,
  shouldStopPayment,
  handleViewDocs,
}: {
  payments: VPaymentTab[];
  getPayments: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
  setSelectedPayment: (payment: VPaymentTab | null) => void;
  selectedPayment: VPaymentTab | null;
  transferPayment: (payment: VPaymentTab) => void;
  shouldVoidPayment: (payment: VPaymentTab) => void;
  shouldStopPayment: (payment: VPaymentTab) => void;
  handleViewDocs: (payment: VPaymentTab) => void;
}) {
  let { claimNumber } = useParams();

  const { claim } = useAppSelector((state) => state.currentClaimReducer);
  const { userModel } = useAppSelector((state) => state.user);
  const [sorting, setSorting] = useState<SortingState>([]);

  const handleStopPaymentDisable = (status: string | null) => {
    return !(status?.toUpperCase() === 'P' || status?.toUpperCase() === 'N');
  };
  const handleVoidPaymentDisable = (status: string | null) => {
    return status?.toUpperCase() === 'V';
  };

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const tableData = useMemo(() => payments, [payments]);

  const booleanFilter: FilterFn<VPaymentTab> = (
    row,
    columnId,
    value,
    addMeta
  ) => {
    let item = row.getValue(columnId) as string;
    if (item === null) {
      item = 'false';
    } else {
      item = item.toString();
    }
    return item.includes(value.toLowerCase());
  };

  const isWithinRange = (row: any, columnId: any, value: any) => {
    const date = row.getValue(columnId);
    const [start, end] = value;
    //If one filter defined and date is null filter it
    if ((start || end) && !date) return false;
    if (start && !end) {
      return new Date(date).getTime() >= new Date(start).getTime();
    } else if (!start && end) {
      return new Date(date).getTime() <= new Date(end).getTime();
    } else if (start && end) {
      return (
        new Date(date).getTime() >= new Date(start).getTime() &&
        new Date(date).getTime() <= new Date(end).getTime()
      );
    } else return true;
  };

  const columnData: ColumnDef<VPaymentTab>[] = [
    {
      header: '',
      id: 'select',
      enableSorting: false,
      enableColumnFilter: false,
      minSize: 235,
      footer: 'Totals',
      cell: (d) => {
        return (
          <div
            className={`d-flex justify-content-center ${
              d.row.original.txId === selectedPayment?.txId ? 'orange' : ''
            }`}
          >
            {userModel?.userSiteFunctions?.some(
              (f) =>
                f.siteFunctionId === SiteFunctionsEnum.EditPayments && f.enabled
            ) && (
              <Button
                type='button'
                size='sm'
                variant='primary'
                title='Edit/View'
                onClick={() => {
                  setSelectedPayment(d.row.original);
                  setShow(true);
                }}
              >
                <FaEdit />
              </Button>
            )}
            <Button
              type='button'
              size='sm'
              variant='secondary'
              title='Transfer'
              onClick={() => {
                setSelectedPayment(d.row.original);
                transferPayment(d.row.original);
              }}
            >
              Transfer
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Stop Payment'
              onClick={() => {
                setSelectedPayment(d.row.original);
                shouldStopPayment(d.row.original);
              }}
              disabled={handleStopPaymentDisable(d.row.original.status)}
            >
              <FaRegStopCircle />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='danger'
              title='Void'
              onClick={() => {
                setSelectedPayment(d.row.original);
                shouldVoidPayment(d.row.original);
              }}
              disabled={handleVoidPaymentDisable(d.row.original.status)}
            >
              <FaBan />
            </Button>
            <Button
              type='button'
              size='sm'
              variant='secondary'
              title='View Docs'
              onClick={() => {
                setSelectedPayment(d.row.original);
                handleViewDocs(d.row.original);
              }}
            >
              Docs
            </Button>
          </div>
        );
      },
    },
    {
      header: 'TX Date',
      accessorFn: (d) => (d.txDate ? displayDateOnly(d.txDate as string) : ''),
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',

      maxSize: 100,
    },
    {
      header: 'SVC From',
      accessorFn: (d) =>
        d.serviceFrom ? displayDateOnly(d.serviceFrom as string) : '',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'SVC To',
      accessorFn: (d) =>
        d.serviceTo ? displayDateOnly(d.serviceTo as string) : '',
      sortingFn: 'datetime',
      filterFn: 'equals',
      cell: (d) =>
        d.getValue() ? displayDateOnly(d.getValue() as string) : '',
      maxSize: 100,
    },
    {
      header: 'Payee',
      accessorKey: 'payee',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Payee Name',
      accessorKey: 'checkPayableTo',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Tax Id',
      accessorKey: 'taxId',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'TX Amount',
      accessorFn: (d) => `${d.txAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
      cell: (d) => accounting.formatMoney(d.row.original.txAmount ?? 0),
      footer: (p) => {
        const total = tableData.reduce((acc, curr) => {
          return (acc += curr.txAmount ?? 0);
        }, 0);
        return accounting.formatMoney(total);
      },
    },
    {
      header: 'Check Amount',
      accessorFn: (d) => `${d.checkAmount}`,
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 120,
      cell: (d) => accounting.formatMoney(d.row.original.checkAmount ?? 0),
      footer: (p) => {
        const total = tableData.reduce((acc, curr) => {
          return (acc += curr.checkAmount ?? 0);
        }, 0);
        return accounting.formatMoney(total);
      },
    },
    {
      header: 'Check #',
      accessorKey: 'checkNumber',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 100,
    },
    {
      header: 'Pay Code',
      accessorKey: 'payCode',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
    },
    {
      header: 'Financial Bucket',
      accessorKey: 'fBucket',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 130,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      sortingFn: 'alphanumeric',
      filterFn: 'includesString',
      maxSize: 75,
    },
    {
      header: 'Cleared',
      accessorKey: 'isCleared',
      enableSorting: false,
      filterFn: booleanFilter,
      maxSize: 75,
      cell: (d) => (
        <Form.Check
          type='checkbox'
          checked={!!d.row.original.isCleared}
          id={`isCleared-${d.row.id}`}
          label=''
          readOnly
        />
      ),
    },
  ];

  const columns = useMemo(
    () => columnData,
    [userModel, selectedPayment, columnData]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    columnResizeMode: 'onChange',
    enableMultiRowSelection: false,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      <div className='py-2 d-flex justify-content-center'>
        {userModel?.userSiteFunctions?.some(
          (f) =>
            f.siteFunctionId === SiteFunctionsEnum.EnterPaymentsClaims &&
            f.enabled
        ) && (
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              setSelectedPayment(null);
              setShow(true);
            }}
            disabled={claimNumber === undefined || claim?.severity === 'IO'}
          >
            <div className='button-icon-text'>
              <FaPlusCircle /> New Payment
            </div>
          </Button>
        )}
      </div>
      <PaginatedTable
        table={table}
        columnResizeMode='onChange'
        showFilters={true}
        showFooter={true}
        // selectableRow={true}
        highlightRow={true}
        setSelectedItem={setSelectedPayment}
      />
    </div>
  );
}
