import React, { useState, useEffect } from 'react';
import { FormApi } from 'final-form';
import { Button, Form } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { Claim } from '../../../ApiTypes/Claim';
import { useAppDispatch, useAppSelector } from '../../../Reducers/Store';
import FieldBSRenderCheckbox from '../../Common/FieldBSRenderCheckbox';
import FieldBSRenderDate from '../../Common/FieldBSRenderDate';
import styles from './index.module.css';
import Calculator from '../../Calculator/Calculator';
import { toast } from 'react-toastify';
import ClaimApi from '../../../Api/ClaimApi';
import { fetchClaimByClaimNumber } from '../../../Actions/ClaimActions';
import RecurringPaymentDetail from '../../RecurringPaymentDetail/RecurringPaymentDetail';
import { parseDatesForServer } from '../../../Utils';
import RecurringPaymentsApi from '../../../Api/RecurringPaymentsApi';
import { useParams } from 'react-router-dom';
import { RecurringPayment } from '../../../ApiTypes/RecurringPayment';
import RecurringPaymentsTable from './RecurringPaymentsTable';
import { centsValidation } from '../../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../../Utils/InputFormatters';
import FieldBSRenderMoney from '../../Common/FieldBSRenderMoney';
import RecurringPaymentWeeklyDetailModal from '../../RecurringPaymentWeeklyDetailModal/RecurringPaymentWeeklyDetailModal';
import SendIndemnityDetailsModal from './SendIndemnityDetailsModal';
import EmailDocumentsModal from '../../EmailDocuments/EmailDocumentsModal';
import { EmailDocumentsRequest } from '../../../ApiTypes/EmailDocumentsRequest';
import UserEmailListApi from '../../../Api/UserEmailListApi';
import { UserEmailList } from '../../../ApiTypes/UserEmailList';
import { ScanDocSimple } from '../../../ApiTypes/ScanDocSimple';
import DocumentsApi from '../../../Api/DocumentsApi';
import EdiDetailModal from '../../EdiDetailModal/EdiDetailModal';

interface FormType extends Claim {
  isTpd: boolean;
}

export default function RecurringTab() {
  const dispatch = useAppDispatch();
  let { claimNumber } = useParams();
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [showRecurringPaymentDetail, setShowRecurringPaymentDetail] =
    useState<boolean>(false);
  const [
    showRecurringPaymentWeeklyDetailModal,
    setShowRecurringPaymentWeeklyDetailModal,
  ] = useState<boolean>(false);
  const [recurringPayments, setRecurringPayments] = useState<
    RecurringPayment[]
  >([]);
  const [selectedRP, setSelectedRP] = useState<RecurringPayment | null>(null);
  const [showSendDetails, setShowSendDetails] = useState<boolean>(false);
  const [showEmailDocumentsModal, setShowEmailDocumentsModal] = useState(false);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [userEmailList, setUserEmailList] = useState<UserEmailList[]>([]);
  const [scanDocToSend, setScanDocToSend] = useState<ScanDocSimple | null>(
    null
  );
  const [toEmailAddresses, setToEmailAddresses] = useState<string[]>([]);
  const [showEdiDetailModal, setShowEdiDetailModal] = useState<boolean>(false);

  const { claim, isTpd } = useAppSelector((state) => state.currentClaimReducer);
  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    getRecurringPaymentsForClaim();
    setExpiringIndemnityReminders();
    return () => {
      setExpiringIndemnityReminders();
    };
  }, [claimNumber]);

  useEffect(() => {
    getUserEmailList();
  }, [userModel]);

  let formInstance: FormApi<FormType, Partial<FormType>>;

  const getUserEmailList = () => {
    if (userModel?.user?.userId) {
      UserEmailListApi.getEmailList(userModel?.user?.userId)
        .then((res) => {
          setUserEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // this needs to run a stored procedure to create diaries.
  const setExpiringIndemnityReminders = () => {
    RecurringPaymentsApi.insertReminders()
      .then((res) => {
        if (!res.data.success) {
          console.log(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRecurringPaymentsForClaim = () => {
    if (claimNumber) {
      RecurringPaymentsApi.getRecurringPaymentsByClaimNumber(claimNumber)
        .then((res) => {
          setRecurringPayments(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSubmit = (values: FormType) => {
    if (values.claimNo) {
      return ClaimApi.updateClaim(values)
        .then((res) => {
          if (res.data.success) {
            toast.success('Claim Saved!', { autoClose: 1000 });
            dispatch(fetchClaimByClaimNumber(values.claimNo));
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const calculateWeeklyWage = async (values: FormType) => {
    let aww = 0;
    let cr = 0;
    const { avgWeeklyWage } = values;
    if (avgWeeklyWage && avgWeeklyWage > 0) {
      aww = avgWeeklyWage;
    }
    const maxForDate = await getMaxBenefitByDate(
      values.injuryDate!,
      values.isTpd
    );

    const twoThirds = 2 / 3;
    cr = aww * twoThirds;
    if (cr > (maxForDate ?? 0)) {
      cr = maxForDate ?? 675;
    }
    const fixed = cr.toFixed(2);

    formInstance.change('hourlyWage', +fixed);
  };

  const getMaxBenefitByDate = (injuryDate: string, isTpd: boolean) => {
    return RecurringPaymentsApi.getMaxBenefitForDate(injuryDate, isTpd)
      .then((res) => {
        return res.data.maxForDate;
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get max benefit by date');
      });
  };

  const onInsertPaymentClick = () => {
    if (!claim?.disabilityDate) {
      toast.error('Please Enter a Disability Date and Save');
      return;
    }
    if (!claim?.avgWeeklyWage || claim.avgWeeklyWage === 0) {
      toast.error('Please enter a Avg Weekly Wage and Save');
      return;
    }
    if (!claim?.hourlyWage || claim.hourlyWage === 0) {
      toast.error('Please enter a Comp Rate and Save');
      return;
    }
    setShowRecurringPaymentDetail(true);
  };

  const getScanDocSimple = (id: number) => {
    return DocumentsApi.getScanDocSimple(claimNumber!, id)
      .then((res) => {
        setScanDocToSend(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get document to share');
      });
  };

  const handleSendDetails = (rp: RecurringPayment) => {
    setSelectedRP(rp);
    setShowSendDetails(true);
  };

  const handleSendIndemnityResponse = async (send: EmailDocumentsRequest) => {
    setSubject(send.subject);
    setBody(send.message);
    setToEmailAddresses(send.emailAddresses);
    await getScanDocSimple(send.docIds[0]);

    setShowEmailDocumentsModal(true);
  };

  const handleEmailDocuments = (values: EmailDocumentsRequest) => {
    return DocumentsApi.emailDocs(values)
      .then((res) => {
        if (res.data.success) {
          setShowEmailDocumentsModal(false);
          setScanDocToSend(null);
          setBody('');
          setSubject('');
          setToEmailAddresses([]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to share documents');
      });
  };

  return (
    <div className='w-100 h-100'>
      <div>
        <RFFForm
          onSubmit={onSubmit}
          initialValues={
            claim
              ? { ...claim, isTpd: isTpd }
              : { avgWeeklyWage: '', hourlyWage: '' }
          }
          render={({ handleSubmit, form, values, submitting }) => {
            formInstance = form;
            return (
              <Form onSubmit={handleSubmit}>
                <fieldset
                  disabled={
                    claim
                      ? claim?.severity === 'MO' || claim?.severity === 'AM'
                      : false
                  }
                >
                  <div className={`${styles.recurringGrid}`}>
                    <div>
                      <Field
                        name='avgWeeklyWage'
                        label='Avg Weekly Wage'
                        type='text'
                        format={formatNumbers}
                        parse={cleanMoney}
                        validate={centsValidation}
                        component={FieldBSRenderMoney}
                      />
                      <Field
                        name='hourlyWage'
                        label='Comp Rate'
                        type='text'
                        format={formatNumbers}
                        parse={cleanMoney}
                        validate={centsValidation}
                        component={FieldBSRenderMoney}
                      />
                    </div>
                    <div className='d-flex flex-column justify-content-around'>
                      <div>
                        <Field
                          name='isTpd'
                          type='checkbox'
                          label='is TPD'
                          readOnly
                          checked={values.isTpd === true}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                      <Button
                        type='button'
                        variant='secondary'
                        onClick={() => calculateWeeklyWage(values)}
                        size='sm'
                      >
                        Calculate
                      </Button>
                      <Button
                        type='button'
                        variant='outline-primary'
                        onClick={() =>
                          setShowCalculator((showCalculator) => !showCalculator)
                        }
                        size='sm'
                      >
                        1/2
                      </Button>
                    </div>
                    <div className='d-flex flex-column justify-content-start'>
                      <Field
                        name='disabilityDate'
                        label='Original Disability Date'
                        parse={parseDatesForServer}
                        component={FieldBSRenderDate}
                      />
                      <Button
                        type='submit'
                        variant='primary'
                        size='sm'
                        className='mt-4 align-self-center px-3'
                      >
                        Save
                      </Button>
                    </div>
                    <div className='pt-3'>
                      <div className={styles.weekChecks}>
                        <Field
                          name='workScheduleMonday'
                          type='checkbox'
                          label='Monday'
                          checked={!!values?.workScheduleMonday}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='workScheduleTuesday'
                          type='checkbox'
                          label='Tuesday'
                          checked={!!values?.workScheduleTuesday}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='workScheduleWednesday'
                          type='checkbox'
                          label='Wednesday'
                          checked={!!values?.workScheduleWednesday}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='workScheduleThursday'
                          type='checkbox'
                          label='Thursday'
                          checked={!!values?.workScheduleThursday}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='workScheduleFriday'
                          type='checkbox'
                          label='Friday'
                          checked={!!values?.workScheduleFriday}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                      <div className={styles.weekChecks}>
                        <Field
                          name='workScheduleSaturday'
                          type='checkbox'
                          label='Saturday'
                          checked={!!values?.workScheduleSaturday}
                          component={FieldBSRenderCheckbox}
                        />
                        <Field
                          name='workScheduleSunday'
                          type='checkbox'
                          label='Sunday'
                          checked={!!values?.workScheduleSunday}
                          component={FieldBSRenderCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </Form>
            );
          }}
        />
      </div>
      <hr />
      <div className={`${styles.weekChecks} justify-content-center mb-3`}>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={onInsertPaymentClick}
          disabled={claim?.severity === 'MO' || claim?.severity === 'AM'}
        >
          Insert Payment
        </Button>
        <Button
          type='button'
          variant='primary'
          size='sm'
          onClick={() => setShowEdiDetailModal(true)}
        >
          View EDI
        </Button>
      </div>
      <RecurringPaymentsTable
        recurringPayments={recurringPayments}
        selectedRP={selectedRP}
        setSelectedRP={setSelectedRP}
        setShowRecurringPaymentDetail={setShowRecurringPaymentDetail}
        setShowRecurringPaymentWeeklyDetailModal={
          setShowRecurringPaymentWeeklyDetailModal
        }
        handleSendDetails={handleSendDetails}
      />
      <Calculator show={showCalculator} setShow={setShowCalculator} />
      {showRecurringPaymentDetail && (
        <RecurringPaymentDetail
          show={showRecurringPaymentDetail}
          setShow={setShowRecurringPaymentDetail}
          selectedRP={selectedRP}
          setSelectedRP={setSelectedRP}
          getRecurringPaymentsForClaim={getRecurringPaymentsForClaim}
        />
      )}
      {showRecurringPaymentWeeklyDetailModal && (
        <RecurringPaymentWeeklyDetailModal
          show={showRecurringPaymentWeeklyDetailModal}
          setShow={setShowRecurringPaymentWeeklyDetailModal}
          setSelectedRP={setSelectedRP}
          selectedRP={selectedRP}
        />
      )}
      <SendIndemnityDetailsModal
        show={showSendDetails}
        setShow={setShowSendDetails}
        rp={selectedRP}
        setSelectedRP={setSelectedRP}
        getRecurringPaymentsForClaim={getRecurringPaymentsForClaim}
        handleSendIndemnityResponse={handleSendIndemnityResponse}
      />
      <EmailDocumentsModal
        show={showEmailDocumentsModal}
        setShow={setShowEmailDocumentsModal}
        claimNumber={claimNumber!}
        docs={scanDocToSend ? [scanDocToSend] : []}
        handleEmailDocuments={handleEmailDocuments}
        userEmailList={userEmailList}
        subject={subject}
        body={body}
        toEmailAddresses={toEmailAddresses}
      />
      <EdiDetailModal
        show={showEdiDetailModal}
        setShow={setShowEdiDetailModal}
      />
    </div>
  );
}
