import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { isDate, format, getDate } from 'date-fns';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { createDateObject, formatDateInputs } from '../../Utils';
import { FaRegCalendar } from 'react-icons/fa';

import styles from './index.module.css';

const redBorder = {
  borderColor: 'red',
  width: '100%',
};

type RenderDatePickerProps = {
  input: any;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  minDate: Date | null;
  className: string;
  endOfDay: boolean;
  startOfDay: boolean;
  onBlur: (v?: any) => void;
  meta: { touched: boolean; error: string; warning: string };
};

export default function FieldBSRenderDate({
  input,
  name,
  label,
  required,
  disabled,
  minDate,
  className,
  endOfDay,
  startOfDay,
  onBlur,
  meta: { touched, error, warning },
}: RenderDatePickerProps) {
  const ref = React.createRef();

  const handleChange = (val: null | Date) => {
    if (isDate(val) && val instanceof Date) {
      const formatted = format(val, 'MM/dd/yyyy');
      input.onChange(formatted);
      input.onBlur();
      onBlur && onBlur();
    } else {
      input.onChange(val);
      input.onBlur();
      onBlur && onBlur();
    }
  };

  const twoDigitOnBlur = (val: null | string) => {
    if (val) {
      if (val.includes('/') || val.includes('T')) {
        var test = new Date(val);
        handleChange(test);
      } else {
        const myArray = val.split('-');
        var test = new Date(
          Number(myArray[0]),
          Number(myArray[1]) - 1,
          Number(myArray[2])
        );
        handleChange(test);
      }
    }
  };

  const CustomInput = forwardRef(({ disabled, onClick }: any, ref) => {
    return (
      <Button
        type='button'
        className={styles.datePickerButton}
        disabled={disabled}
        onClick={onClick}
        variant='outline-primary'
        size='sm'
        tabIndex={-1}
      >
        <FaRegCalendar className='pe-1' />
      </Button>
    );
  });

  return (
    <div className={`position-relative ${styles.vFieldHeight}`}>
      <label htmlFor={input.name} className='form-label fs-6  m-0'>
        {label}
      </label>
      <div>
        <InputGroup className='flex-nowrap'>
          <Form.Control
            {...input}
            id={input.name}
            value={formatDateInputs(input.value, startOfDay, endOfDay)}
            onBlur={(e) => {
              if (input.value) {
                twoDigitOnBlur(input.value);
                input.onBlur(e);
                onBlur && onBlur(e);
              }
            }}
            className={className}
            placeholder='MM/DD/YYYY'
            type='text'
            style={touched && error ? { ...redBorder } : {}}
            disabled={disabled}
            size='sm'
          />
          <DatePicker
            popperClassName={`${styles.front}`}
            selected={
              isDate(input.value)
                ? input.value
                : !input.value
                ? null
                : createDateObject(input.value)
            }
            // onSelect={handleChange}
            onChange={handleChange}
            name={input.name}
            className={className}
            disabled={disabled}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            minDate={minDate}
            popperPlacement='top-end'
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [5, 10],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'document',
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom', 'right', 'left'],
                  rootBoundary: 'document',
                  altBoundary: true,
                },
              },
            ]}
            customInput={<CustomInput disabled={disabled} ref={ref} />}
          />
        </InputGroup>
        {touched &&
          ((error && (
            <span className={`${styles.fieldError} text-danger`}>{error}</span>
          )) ||
            (warning && (
              <span className={`${styles.fieldWarning} text-warning`}>
                {warning}
              </span>
            )))}
      </div>
    </div>
  );
}
