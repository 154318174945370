import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Form as RFForm, Field } from 'react-final-form';
import { FaEdit } from 'react-icons/fa';
import { useAppSelector } from '../../../Reducers/Store';
import { requiredField } from '../../../Utils/FieldValidation';
import FieldBSRenderText from '../../Common/FieldBSRenderText';
import styles from './index.module.css';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import UserApi from '../../../Api/UserApi';
import { EntityUser } from '../../../ApiTypes/EntityUser';
import { RichEdit } from 'devexpress-richedit';
import 'devexpress-richedit/dist/dx.richedit.css';
import { EmailTemplate } from '../../../ApiTypes/EmailTemplate';
import EmailTemplateApi from '../../../Api/EmailTemplateApi';
import ReactQuill from 'react-quill';
import { EmailTemplateOptions } from './EmailTemplateOptions';

export default function EmailTemplateModal({
  show,
  setShow,
  selectedTemplate,
  getEmailTemplateList,
  isAdmin,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedTemplate: EmailTemplate | null;
  getEmailTemplateList: () => void;
  isAdmin: boolean;
}) {
  const [quillValue, setQuillValue] = useState('');
  const quillRef = useRef<ReactQuill>(null);

  const { userModel } = useAppSelector((state) => state.user);
  const [usersList, setUsersList] = useState<EntityUser[]>([]);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['yellow', 'red', '#785412'] }],
      ['clean'],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'align',
  ];

  useEffect(() => {
    setQuillValue(
      selectedTemplate
        ? (selectedTemplate.emailBody ?? '').replace(/(?:\r\n|\r|\n)/g, '<br>')
        : ''
    );
  }, [selectedTemplate]);

  const onSubmit = (values: EmailTemplate) => {
    return saveTemplate(values);
  };

  const handleOptionChange = (value: string) => {
    if (quillRef && quillRef.current && quillRef.current.editor) {
      const selection = quillRef.current.selection;
      if (value && selection) {
        quillRef.current.editor.insertText(selection.index, `[${value}]`, {});
      }
    }
  };

  const getUsersList = () => {
    UserApi.getUsersList()
      .then((res) => {
        setUsersList(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to users list');
      });
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const saveTemplate = (values: EmailTemplate) => {
    values.emailBody = quillValue;
    return EmailTemplateApi.addUpdateEmailTemplate(values)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setShow(false);
          getEmailTemplateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update');
      });
  };
  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setQuillValue('');
        setShow(false);
      }}
      aria-labelledby='EmailTemplateModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='EmailTemplateModal-modal'>
          <FaEdit className='pe-1' /> Email Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={
              selectedTemplate
                ? selectedTemplate
                : { createdBy: !isAdmin ? userModel?.user?.userId : null }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <Field
                      name='name'
                      type='text'
                      label='Name'
                      component={FieldBSRenderText}
                      validate={requiredField}
                    />

                    <div className={`${styles.centerContent}`}>
                      <div className={`${styles.topWindow}`}>
                        <div className={`${styles.documentWindow}`}>
                          <ReactQuill
                            ref={quillRef}
                            theme='snow'
                            value={quillValue}
                            onChange={setQuillValue}
                            modules={modules}
                            formats={formats}
                            className={styles.noteContainer}
                          />
                        </div>
                        <div className={`${styles.buttonColumn}`}>
                          {EmailTemplateOptions.map((o) => (
                            <Button
                              key={`1${o}`}
                              type='button'
                              variant='outline-primary'
                              size='sm'
                              onClick={() => handleOptionChange(o)}
                            >
                              {o}
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-around align-items-center py-3'>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
