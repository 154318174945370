import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { FaFolderOpen, FaPlusCircle } from 'react-icons/fa';
import PageScaffold from '../PageScaffold/PageScaffold';
import { useParams } from 'react-router-dom';
import { ExcessCarrierPolicy } from '../../ApiTypes/ExcessCarrierPolicy';
import ExcessCarrierApi from '../../Api/ExcessCarrierApi';
import { toast } from 'react-toastify';
import ExcessPolicyFullTable from './ExcessPolicyFullTable';
import ExcessCarrierPolicyApi from '../../Api/ExcessCarrierPolicyApi';
import { Button } from 'react-bootstrap';
import AddEditExcessPolicy from '../PoliciesMaintenance/AddEditExcessPolicy';
import BSSelect from '../Common/BSSelect';
import { ExcessCarrier } from '../../ApiTypes/ExcessCarrier';
import AddEditExcessCarrier from '../AddEcitExcessCarier/AddEditExcessCarrier';

export default function ExcessPoliciesFull() {
  const [policies, setPolicies] = useState<ExcessCarrierPolicy[]>([]);
  const [excessCarriers, setExcessCarriers] = useState<ExcessCarrier[]>([]);
  const [showAddEditCarrier, setShowAddEditCarrier] = useState<boolean>(false);
  const [selectedExcessCarrier, setSelectedExcessCarrier] =
    useState<ExcessCarrier | null>(null);
  const [selectedPolicy, setSelectedPolicy] =
    useState<ExcessCarrierPolicy | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  useEffect(() => {
    refreshData();
  }, []);

  const getCarriers = () => {
    ExcessCarrierApi.list()
      .then((res) => {
        setExcessCarriers(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get carriers');
      });
  };

  const getPolicies = () => {
    ExcessCarrierPolicyApi.getExcessPolicyList()
      .then((res) => {
        setPolicies(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to get policies');
      });
  };

  const refreshData = () => {
    getCarriers();
    getPolicies();
  };

  const handleDelete = (policy: ExcessCarrierPolicy) => {
    return ExcessCarrierPolicyApi.delete(policy.excessCarrierPolicyId!)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          getPolicies();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to delete policy');
      });
  };

  const handleEdit = (policy: ExcessCarrierPolicy) => {
    setSelectedPolicy(policy);
    setShowEdit(true);
  };

  const handleEditCarrier = (excessCarrierId: number) => {
    const excessCarrier = excessCarriers.find(
      (x) => x.excessCarrierId === excessCarrierId
    );
    if (excessCarrier) {
      setSelectedExcessCarrier(excessCarrier);
      setShowAddEditCarrier(true);
    }
  };

  const setNull = () => {
    setSelectedPolicy(null);
  };

  return (
    <PageScaffold>
      <div className={`${styles.pageTop} bg-light mb-3`}>
        <div className='ps-3 d-flex'>
          <FaFolderOpen className='fs-1 text-primary mt-1' />
          <div className='ms-3'>
            <h1>All Excess Policies</h1>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3'>
        {/* <div className='d-flex align-items-center gap1Rem'>
          <BSSelect
            name='excessCarrierId'
            label='Excess Carrier'
            // onChange={setDocTypeId}
            options={excessCarriers.sort((a, b) =>
              (a.name ?? '').localeCompare(b.name ?? '')
            )}
            optionMethod={(options: ExcessCarrier[]) =>
              options.map((o) => (
                <option key={o.excessCarrierId} value={o.excessCarrierId}>
                  {o.name}
                </option>
              ))
            }
          />
          <Button
            type='button'
            variant='primary'
            size='sm'
            // onClick={getAll}
          >
            Search
          </Button>
        </div> */}
        <div>
          <Button
            type='button'
            variant='primary'
            size='sm'
            className='button-icon-text'
            onClick={() => {
              setSelectedPolicy(null);
              setShowEdit(true);
            }}
          >
            <FaPlusCircle /> New
          </Button>
        </div>
      </div>
      <ExcessPolicyFullTable
        data={policies}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleEditCarrier={handleEditCarrier}
      />
      <>
        {showEdit && (
          <AddEditExcessPolicy
            show={showEdit}
            setShow={setShowEdit}
            excessPolicyId={selectedPolicy?.excessCarrierPolicyId ?? 0}
            excessCarrierId={selectedPolicy?.excessCarrierId ?? 0}
            // excessCarrierId={+excessCarrierId!}
            refreshData={getPolicies}
            setNull={setNull}
          />
        )}
        <AddEditExcessCarrier
          show={showAddEditCarrier}
          setShow={setShowAddEditCarrier}
          fetchData={refreshData}
          excessCarrier={selectedExcessCarrier}
        />
      </>
    </PageScaffold>
  );
}
