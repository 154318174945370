import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseApiModel } from '../ApiTypes/ResponseApiModel';
import { TextLog } from '../ApiTypes/TextLog';
import { TextGridObj } from '../ApiTypes/TextGridObj';
import { EmailTemplate } from '../ApiTypes/EmailTemplate';

class EmailTemplateApi {
  static getEmailTemplateList(userId: string, insurcoid: number) {
    return axios.get<EmailTemplate[]>(
      `${API_URL}/api/emailtemplate/user/${userId}/insurco/${insurcoid}`,
      getAuthHeader()
    );
  }
  static getEmailTemplateListFull() {
    return axios.get<EmailTemplate[]>(
      `${API_URL}/api/emailtemplate`,
      getAuthHeader()
    );
  }
  static getEmailTemplateListCustom(userId: string, insurcoid: number) {
    return axios.get<EmailTemplate[]>(
      `${API_URL}/api/emailtemplate/custom/user/${userId}/insurco/${insurcoid}`,
      getAuthHeader()
    );
  }
  static addUpdateEmailTemplate(request: EmailTemplate) {
    return axios.post<ResponseApiModel>(
      `${API_URL}/api/emailtemplate`,
      request,
      getAuthHeader()
    );
  }
  static deleteEmailTemplate(id: number) {
    return axios.delete<ResponseApiModel>(
      `${API_URL}/api/emailtemplate/${id}`,
      getAuthHeader()
    );
  }
}

export default EmailTemplateApi;
