import React, { useState, useEffect } from 'react';
import { FormApi } from 'final-form';
import {
  Modal,
  Container,
  Form,
  Accordion,
  Button,
  Spinner,
} from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { Field, Form as RFFForm } from 'react-final-form';
import { FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LegalApi from '../../Api/LegalApi';
import ProviderApi from '../../Api/ProviderApi';
import { ProviderSearchResult } from '../../ApiTypes/ProviderSearchResult';
import { SettlementPaymentDetail } from '../../ApiTypes/SettlementPaymentDetail';
import { SettlementPaymentDetailPayCodeType } from '../../ApiTypes/SettlementPaymentDetailPayCodeType';
import { SettlementPaymentDetailType } from '../../ApiTypes/SettlementPaymentDetailType';
import { centsValidation } from '../../Utils/FieldValidation';
import { formatNumbers, cleanMoney } from '../../Utils/InputFormatters';
import FieldBSRenderMoney from '../Common/FieldBSRenderMoney';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import ProviderLookUpForm from '../ProviderLookup/ProviderLookUpForm';
import styles from './index.module.css';

export default function SettlementPaymentDetailAddEdit({
  show,
  setShow,
  details,
  getSettlementById,
  claimantName,
  settlementId,
  getSettlements,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  details: SettlementPaymentDetail | null;
  getSettlementById: () => void;
  claimantName: string;
  settlementId: string;
  getSettlements: () => void;
}) {
  let { claimNumber } = useParams();
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('');
  const [taxIdText, setTaxIdText] = useState<string>('');
  const [provider, setProvider] = useState<ProviderSearchResult | null>(null);

  let formInstance: FormApi<
    SettlementPaymentDetail,
    Partial<SettlementPaymentDetail>
  >;
  useEffect(() => {
    getInitialValues();
  }, [details]);

  const getInitialValues = async () => {
    if (details) {
      if ((details?.type ?? 0) > 1) {
        if (details.providerId) {
          const provider = await getProviderById(details.providerId);
          if (provider) {
            setTaxIdText(provider.taxId);
            formInstance.change('displayName', provider.companyName);
          }
        }
      } else {
        setTaxIdText('Claimant SSN');
        formInstance.change('displayName', claimantName);
      }
    } else {
      setTaxIdText('Claimant SSN');
      if (formInstance) formInstance.change('displayName', claimantName);
    }
  };

  const getProviderById = (providerId: number) => {
    return ProviderApi.getProviderById(providerId)
      .then((res) => {
        const p = res.data.find((p) => p.providerId === providerId);
        if (!p) {
          toast.error('Failed to get Provider');
        }
        setProvider(p ?? null);
        return p;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let formValues: SettlementPaymentDetail;

  const onSubmit = (values: SettlementPaymentDetail) => {
    let data: SettlementPaymentDetail = {
      settlementId: settlementId ? +settlementId : 0,
      id: values.id,
      type: values.type,
      typeName: values.type ? SettlementPaymentDetailType[values.type] : null,
      amount: values.amount,
      providerId: 0,
      attorneyFein: '',
      displayName: values.displayName,
      payCodeIndex: values.payCodeIndex,
      attorneyId: null,
      attorney: null,
    };

    if (values.type !== null ? +values.type !== 1 : false) {
      data.displayName = provider?.companyName ?? '';
      data.providerId = provider?.providerId ?? null;
    }

    if (details?.id) {
      return update(data);
    } else {
      return create(data);
    }
  };

  const create = (d: SettlementPaymentDetail) => {
    return LegalApi.createSettlementPaymentDetail(d, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          setProvider(null);
          setTaxIdText('');
          getSettlementById();
          getSettlements();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to create payment details');
      });
  };

  const update = (d: SettlementPaymentDetail) => {
    return LegalApi.updateSettlementPaymentDetail(d, claimNumber!)
      .then((res) => {
        if (res.data.success) {
          setShow(false);
          setProvider(null);
          setTaxIdText('');
          getSettlementById();
          getSettlements();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to update payment details');
      });
  };

  const typeOptions: { name: string; value: number }[] = [
    {
      name: SettlementPaymentDetailType[1],
      value: SettlementPaymentDetailType.Claimant,
    },
    {
      name: SettlementPaymentDetailType[2],
      value: SettlementPaymentDetailType.Attorney,
    },
    {
      name: SettlementPaymentDetailType[3],
      value: SettlementPaymentDetailType.Other,
    },
  ];

  const payCodeTypeOptions: { name: string; value: number }[] = [
    {
      name: SettlementPaymentDetailPayCodeType[0],
      value: SettlementPaymentDetailPayCodeType['18 - Settlement'],
    },
    {
      name: SettlementPaymentDetailPayCodeType[1],
      value: SettlementPaymentDetailPayCodeType['37 - MSA'],
    },
  ];

  const handleSelectedProvider = (provider: ProviderSearchResult) => {
    if (provider) {
      setProvider(provider);
      formInstance.change('displayName', provider.companyName);
      setTaxIdText(provider.taxId);
      setActiveKey('');
    }
  };

  const handleActiveKey = (eventKey: AccordionEventKey) => {
    if (formValues.type === null ? false : +formValues.type === 1) {
      setActiveKey('');
      toast.warn('Provider lookup is disabled when type is Claimant');
    } else {
      setActiveKey(eventKey);
    }
  };

  const handleTypeChange = (value: number) => {
    if (+value === 1) {
      setTaxIdText('Claimant SSN');
      formInstance.change('displayName', claimantName);
    } else {
      if (provider) {
        setTaxIdText(provider.taxId);
        formInstance.change('displayName', provider.companyName);
      } else {
        setTaxIdText('');
        formInstance.change('displayName', '');
      }
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setProvider(null);
        setTaxIdText('');
      }}
      dialogClassName=''
      aria-labelledby='Add-Edit-Settlement-payment-detail-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Add-Edit-Settlement-payment-detail-Form-modal'
        >
          <FaPlusCircle className='pe-1' /> Add/Edit Settlement Payment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Accordion
            onSelect={(eventKey: AccordionEventKey) =>
              handleActiveKey(eventKey)
            }
            activeKey={activeKey}
            className='mb-3'
          >
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Provider Look Up</Accordion.Header>
              <Accordion.Body>
                <ProviderLookUpForm
                  selectProvider={handleSelectedProvider}
                  selectedPayment={null}
                  disabled={false}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={
              details ?? {
                id: 0,
                type: 1,
                payCodeIndex: 1,
                displayName: claimantName,
              }
            }
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              formValues = values;
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name='type'
                    label='Type'
                    options={typeOptions}
                    onChange={handleTypeChange}
                    optionMethod={(
                      options: { name: string; value: number }[]
                    ) =>
                      options.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <Field
                    name='amount'
                    label='Amount'
                    type='text'
                    format={formatNumbers}
                    parse={cleanMoney}
                    validate={centsValidation}
                    component={FieldBSRenderMoney}
                  />
                  <div className={`position-relative ${styles.vFieldHeight}`}>
                    <label
                      htmlFor='taxIdLookup'
                      className='form-label fs-6  m-0'
                    >
                      Tax Id Lookup
                    </label>
                    <input
                      type='text'
                      name='taxIdLookup'
                      className='form-control form-control-sm'
                      value={taxIdText}
                      placeholder='Tax Id Lookup'
                      onFocus={() => {
                        if (values.type === null ? false : +values.type === 1) {
                          return;
                        } else {
                          setActiveKey('0');
                        }
                      }}
                      readOnly
                    />
                  </div>
                  <Field
                    name='displayName'
                    label='Payee'
                    type='Text'
                    component={FieldBSRenderText}
                    disabled={values.type === null ? false : +values.type === 1}
                  />
                  <Field
                    name='payCodeIndex'
                    label='Pay Code'
                    options={payCodeTypeOptions}
                    optionMethod={(
                      options: { name: string; value: number }[]
                    ) =>
                      options.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.name}
                        </option>
                      ))
                    }
                    component={FieldBSRenderSelect}
                  />
                  <div className='d-flex justify-content-around align-items-center mt-3'>
                    <Button type='submit' size='sm' variant='primary'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setShow(false);
                        setProvider(null);
                        setTaxIdText('');
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
