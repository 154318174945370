import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Field, Form as RFFForm } from 'react-final-form';
import { FormApi } from 'final-form';
import { FaPlusCircle, FaUser } from 'react-icons/fa';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import FieldBSRenderText from '../Common/FieldBSRenderText';
import FieldBSRenderTextArea from '../Common/FieldRenderTextArea';

import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { Attorney } from '../../ApiTypes/Attorney';
import { requiredField, zipLength } from '../../Utils/FieldValidation';
import { useAppSelector } from '../../Reducers/Store';
import { State } from '../../ApiTypes/State';
import { LawFirm } from '../../ApiTypes/LawFirm';
import { formatPhoneNumbers } from '../../Utils/InputFormatters';

export default function AttorneyAddEditModal({
  show,
  setShow,
  attorneyToEdit,
  returnToAssign,
  setAttorneyToEdit,
  handleAttorneyAddEdit,
  handleNewFirmFromAttorneyAddEdit,
}: {
  show: boolean;
  attorneyToEdit: Attorney | null;
  returnToAssign: boolean;
  setShow: (show: boolean) => void;
  setAttorneyToEdit: (attorney: Attorney | null) => void;
  handleAttorneyAddEdit: (values: Attorney) => Promise<void>;
  handleNewFirmFromAttorneyAddEdit: () => void;
}) {
  let { firmId } = useParams();
  let formInstance: FormApi<Attorney, Partial<Attorney>>;

  const { states, lawFirms } = useAppSelector((state) => state.reference);

  useEffect(() => {
    getInitialState();
  }, [attorneyToEdit, lawFirms, firmId]);

  const getInitialState = () => {
    if (
      attorneyToEdit &&
      attorneyToEdit.firm === null &&
      attorneyToEdit.firmId
    ) {
      const firm = lawFirms.find((l) => l.firmId === attorneyToEdit.firmId);
      if (firm) {
        formInstance.change(
          'firm.streetAddress' as keyof Attorney,
          firm.streetAddress
        );
        formInstance.change(
          'firm.streetAddress1' as keyof Attorney,
          firm.streetAddress1
        );
        formInstance.change('firm.city' as keyof Attorney, firm.city);
        formInstance.change('firm.state' as keyof Attorney, firm.state);
        formInstance.change('firm.zip' as keyof Attorney, firm.zip);
        formInstance.change('firm.firmName' as keyof Attorney, firm.firmName);
        formInstance.change('firm.firmId' as keyof Attorney, firm.firmId);
        if (!attorneyToEdit.streetAddress) {
          formInstance.change(
            'streetAddress' as keyof Attorney,
            firm.streetAddress
          );
          formInstance.change(
            'streetAddress1' as keyof Attorney,
            firm.streetAddress1
          );
          formInstance.change('city' as keyof Attorney, firm.city);
          formInstance.change('state' as keyof Attorney, firm.state);
          formInstance.change('zip' as keyof Attorney, firm.zip);
          formInstance.change('firmName' as keyof Attorney, firm.firmName);
          formInstance.change('firmId' as keyof Attorney, firm.firmId);
        }
      }
    } else if (!attorneyToEdit && firmId) {
      const firm = lawFirms.find((l) => l.firmId === +firmId!);
      if (firm) {
        formInstance.change('firmId' as keyof Attorney, firmId);
        formInstance.change(
          'firm.streetAddress' as keyof Attorney,
          firm.streetAddress
        );
        formInstance.change(
          'firm.streetAddress1' as keyof Attorney,
          firm.streetAddress1
        );
        formInstance.change('firm.city' as keyof Attorney, firm.city);
        formInstance.change('firm.state' as keyof Attorney, firm.state);
        formInstance.change('firm.zip' as keyof Attorney, firm.zip);
        formInstance.change('firm.firmName' as keyof Attorney, firm.firmName);
        formInstance.change('firm.firmId' as keyof Attorney, firm.firmId);
        formInstance.change(
          'streetAddress' as keyof Attorney,
          firm.streetAddress
        );
        formInstance.change(
          'streetAddress1' as keyof Attorney,
          firm.streetAddress1
        );
        formInstance.change('city' as keyof Attorney, firm.city);
        formInstance.change('state' as keyof Attorney, firm.state);
        formInstance.change('zip' as keyof Attorney, firm.zip);
        formInstance.change('firmName' as keyof Attorney, firm.firmName);
        formInstance.change('firmId' as keyof Attorney, firm.firmId);
      }
    }
  };

  const onSubmit = (values: Attorney) => {
    const copy = { ...values };
    return handleAttorneyAddEdit(copy);
  };

  const handleFirmChange = (id: string) => {
    if (id) {
      const firm = lawFirms.find((l) => l.firmId === +id);
      if (firm) {
        formInstance.change(
          'firm.streetAddress' as keyof Attorney,
          firm.streetAddress
        );
        formInstance.change(
          'firm.streetAddress1' as keyof Attorney,
          firm.streetAddress1
        );
        formInstance.change('firm.city' as keyof Attorney, firm.city);
        formInstance.change('firm.state' as keyof Attorney, firm.state);
        formInstance.change('firm.zip' as keyof Attorney, firm.zip);
        formInstance.change('firm.firmName' as keyof Attorney, firm.firmName);
        formInstance.change('firm.firmId' as keyof Attorney, firm.firmId);
      }
    } else {
      formInstance.change('firm.streetAddress' as keyof Attorney, undefined);
      formInstance.change('firm.streetAddress1' as keyof Attorney, undefined);
      formInstance.change('firm.city' as keyof Attorney, undefined);
      formInstance.change('firm.state' as keyof Attorney, undefined);
      formInstance.change('firm.zip' as keyof Attorney, undefined);
      formInstance.change('firm.firmName' as keyof Attorney, undefined);
      formInstance.change('firm.firmId' as keyof Attorney, undefined);
    }
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
        setAttorneyToEdit(null);
      }}
      dialogClassName=''
      aria-labelledby='Edit-Create-Attorney-Form-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title
          className='button-icon-text'
          id='Edit-Create-Attorney-Form-modal'
        >
          <FaUser className='pe-1' /> Attorney Add/Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFFForm
            onSubmit={onSubmit}
            initialValues={attorneyToEdit ?? {}}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={`${styles.twoColumns}`}>
                    <div className='d-flex flex-column w-50'>
                      <Field
                        name='firstName'
                        label='First Name'
                        type='text'
                        validate={requiredField}
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='lastName'
                        label='Last Name'
                        type='text'
                        validate={requiredField}
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='phone'
                        label='Phone'
                        type='text'
                        format={formatPhoneNumbers}
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='cellPhone'
                        label='Cell Phone'
                        type='text'
                        format={formatPhoneNumbers}
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='fax'
                        label='Fax'
                        type='text'
                        format={formatPhoneNumbers}
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='emailAddress'
                        label='Email'
                        type='email'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='streetAddress'
                        label='Address'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='streetAddress1'
                        label='Address 1'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <Field
                        name='city'
                        label='City'
                        type='text'
                        component={FieldBSRenderText}
                      />
                      <div className={`${styles.twoColumns}`}>
                        <Field
                          name='state'
                          label='State'
                          options={states}
                          optionMethod={(options: State[]) =>
                            options.map((o) => (
                              <option key={o.stateAbbr} value={o.stateAbbr}>
                                {o.stateAbbr}
                              </option>
                            ))
                          }
                          component={FieldBSRenderSelect}
                        />
                        <Field
                          name='zip'
                          label='Zip'
                          type='text'
                          validate={zipLength}
                          component={FieldBSRenderText}
                          maxLength={5}
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-column w-50'>
                      <fieldset className='mb-3'>
                        <legend>Firm</legend>
                        {!returnToAssign && (
                          <Button
                            type='button'
                            variant='secondary'
                            size='sm'
                            onClick={handleNewFirmFromAttorneyAddEdit}
                            className='button-icon-text m-auto'
                          >
                            <FaPlusCircle /> New Firm
                          </Button>
                        )}
                        <Field
                          name='firmId'
                          label='Name'
                          options={lawFirms}
                          optionMethod={(options: LawFirm[]) =>
                            options.map((o) => (
                              <option key={o.firmId} value={o.firmId}>
                                {o.firmName}
                              </option>
                            ))
                          }
                          onChange={handleFirmChange}
                          component={FieldBSRenderSelect}
                          // validate={requiredField}
                        />
                        <Field
                          name='firm.streetAddress'
                          label='Address'
                          type='text'
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='firm.streetAddress1'
                          label='Address 1'
                          type='text'
                          component={FieldBSRenderText}
                        />
                        <Field
                          name='firm.city'
                          label='City'
                          type='text'
                          component={FieldBSRenderText}
                        />
                        <div className={`${styles.twoColumns}`}>
                          <Field
                            name='firm.state'
                            label='State'
                            options={states}
                            optionMethod={(options: State[]) =>
                              options.map((o) => (
                                <option key={o.stateAbbr} value={o.stateAbbr}>
                                  {o.stateAbbr}
                                </option>
                              ))
                            }
                            component={FieldBSRenderSelect}
                          />
                          <Field
                            name='firm.zip'
                            label='Zip'
                            type='text'
                            validate={zipLength}
                            component={FieldBSRenderText}
                            maxLength={5}
                          />
                        </div>
                      </fieldset>
                      <Field
                        name='internalNotes'
                        label='Notes'
                        rows={5}
                        component={FieldBSRenderTextArea}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-around mt-3'>
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      onClick={() => {
                        setAttorneyToEdit(null);
                        setShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
