import React, { useEffect, useState } from 'react';
import { Form as RFForm, Field } from 'react-final-form';
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaCheck, FaPlusCircle } from 'react-icons/fa';
import styles from './index.module.css';
import { requiredField } from '../../Utils/FieldValidation';
import FieldBSRenderSelect from '../Common/FieldBSRenderSelect';
import { format } from 'date-fns';
import EdiTransactionApi from '../../Api/EdiTransactionApi';
import { FullClaim } from '../../ApiTypes/FullClaim';
import { FormApi } from 'final-form';
import FroiClaimsTable from './FroiClaimsTable';

export default function FroiClaimsModal({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const [years, setYears] = useState<number[]>([]);
  const [claims, setClaims] = useState<FullClaim[]>([]);
  let formInstance: FormApi<
    { treatyYear: number },
    Partial<{ treatyYear: number }>
  >;

  useEffect(() => {
    loadYears();
  }, []);

  const loadYears = () => {
    var ys: number[] = [];
    var lowYear = new Date().getFullYear() - 1970;
    var thisYear = new Date().getFullYear();
    for (let i = 0; i <= lowYear; i++) {
      ys.push(thisYear - i);
    }
    setYears(ys);
    formInstance.submit();
  };

  const onSubmit = (values: { treatyYear: number }) => {
    return EdiTransactionApi.getLostTimeNoFroi(values.treatyYear)
      .then((res) => {
        setClaims(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      centered
      show={show}
      size='lg'
      onHide={() => {
        setShow(false);
      }}
      dialogClassName=''
      aria-labelledby='FroiClaimsModal-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='button-icon-text' id='FroiClaimsModal-modal'>
          <FaPlusCircle className='pe-1' /> Claims that need FROI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              treatyYear: new Date().getFullYear(),
            }}
            render={({ handleSubmit, form, values, submitting }) => {
              formInstance = form;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='d-flex align-items-center gap1Rem'>
                    <Field
                      name='treatyYear'
                      label='Treaty Year'
                      options={years}
                      optionMethod={(options: number[]) =>
                        options.map((o) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))
                      }
                      validate={requiredField}
                      component={FieldBSRenderSelect}
                    />
                    <Button type='submit' variant='primary' size='sm'>
                      {submitting ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
          <FroiClaimsTable claims={claims} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}
